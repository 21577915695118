import React, { useState, useRef, useCallback } from "react"
import {
  Row,
  Col
} from "reactstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getRolesSelector } from "../../../redux/selectors/AdminSelector";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';

const SubscriptionFilter = (props) => {
  const { Roles, query, setQuery } = props
  const [paidDate, setPaidDate] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const formRef = useRef(null);

  const amountChange = (value) => {
    if (value.keyCode === 13)
      setQuery({
        ...query,
        subscription_amount: value.target.value,
      });
  };

  const remove = useCallback((key) => {
    setQuery(({ [key]: value, ...query }) => query);
  }, []);

  const handlePaidDate = (date) => {
    if (date) {
      setPaidDate(date);
      setQuery({
        ...query,
        subscription_paid_date: moment(date).format("MMM DD, YYYY"),
      });
    } else {
      remove('subscription_paid_date')
      setPaidDate(date);
    }
  };
  
  const handlesEcpireDate= (date) => {
    if (date) {
      setExpireDate(date);
      setQuery({
        ...query,
        subscription_expiry_date: moment(date).format("MMM DD, YYYY"),
      });
    } else {
      remove('subscription_expiry_date')
      setExpireDate(date);
    }
  };

  const clearFilters = () => {
    setPaidDate(null);
    setExpireDate(null);
    setQuery({});
    formRef.current.reset();
  };

  return (
    
    <div className="card candidate-filters  border border-primary">
      <div className="card-header">
        <h5 className="card-title">Subscription Filters</h5>
      </div>
      <form ref={formRef} className=" card-body pb-0 ">
        <Row>
          <Col lg={10}>
            <Row>
              <Col lg={3}>
                <div className="form-group">
                  <select
                    name="subscription"
                    className="form-control"
                    type="select"
                    onChange={(e) => setQuery({ ...query, subscription_name: e.target.value })}
                  >
                    <option value="">Subscription</option>
                    <option value="trial">trial</option>
                    <option value="pro">pro</option>
                    <option value="proPlus">proPlus</option>
                    <option value="premium">premium</option>
                  </select>
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <input
                    name="amount"
                    placeholder="Amount"
                    className="form-control"
                    onKeyUp={(e) => amountChange(e)}
                    type="text"
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      clearable
                      format="yyyy-MM-dd"
                      className="form-control"
                      placeholder="Paid Date"
                      value={paidDate}
                      onChange={(e) =>handlePaidDate(e)
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      clearable
                      format="yyyy-MM-dd"
                      className="form-control"
                      placeholder="Expire Date"
                      value={expireDate}
                      onChange={(e) =>handlesEcpireDate(e)
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={2}>
            <Row className="mb-3">
              <Col lg={12}>
                <div className="form-group">
                  <button type="button" className="btn btn-secondary btn-block" onClick={() => clearFilters()}>
                    Clear Filters
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    Roles: getRolesSelector(state),
  }
}

export default (connect(mapStateToProps, null)(SubscriptionFilter));
