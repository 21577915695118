import React from "react"
import { Nav, Pagination } from "react-bootstrap"
import { Link } from "react-router-dom"
const PaginationComponent = ({ count, pages, page, setPage }) => {
  const active = page
  let items = []
  for (let number = 1; number <= pages; number++) {
    if (pages <= 12) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
          {number}
        </Pagination.Item>
      )
    } else {
      if (page <= 6) {
        if (number <= 7) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        } else if (number === 9) {
          items.push(
            <Pagination.Item key={number}>
              <i className="fa fa-ellipsis-h"></i>
            </Pagination.Item>
          )
        } else if (number >= pages - 1) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        }
      } else if (page > 6 && page <= pages - 7) {
        if (number < 2) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        } else if (number === 2) {
          items.push(
            <Pagination.Item key={number}>
              <i className="fa fa-ellipsis-h"></i>
            </Pagination.Item>
          )
        } else if (number >= page - 2 && number <= page + 2) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        } else if (number === page + 3) {
          items.push(
            <Pagination.Item key={number}>
              <i className="fa fa-ellipsis-h"></i>
            </Pagination.Item>
          )
        } else if (number >= pages - 1) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        }
      } else if (page >= pages - 7) {
        if (number < 2) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        } else if (number === 2) {
          items.push(
            <Pagination.Item key={number}>
              <i className="fa fa-ellipsis-h"></i>
            </Pagination.Item>
          )
        } else if (number >= pages - 7) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setPage(number, true)}>
              {number}
            </Pagination.Item>
          )
        }
      }
    }
  }

  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-1  ${gutter ? "pagination-gutter" : ""} ${variant && `pagination-${variant}`} ${!bg && "no-bg"} ${
        circle && "pagination-circle"
      }`}
    >
      {page > 1 && (
        <li className="page-item page-indicator">
          <Link className="page-link" to="#" onClick={() => setPage(page - 1)}>
            Previous
          </Link>
        </li>
      )}
      {pages > 0 && items}
      {page < pages && pages >= 1 && (
        <li className="page-item page-indicator">
          <Link className="page-link" to="#" onClick={() => setPage(page + 1)}>
            Next
          </Link>
        </li>
      )}
    </Pagination>
  )
  return (
    <>
      <Nav>{pag("", true, "", true, false)}</Nav>
    </>
  )
}
export default PaginationComponent
