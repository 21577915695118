import React, {useEffect, useState} from "react"
import {
    Card,
    CardBody,
    Table,
    Spinner,
} from "reactstrap"
import Pagination from "../common/Pagination/Pagination";
import LogsFilter from "../common/logs/LogsFilter";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import {getSingleUserLogsSelector} from "../../../redux/selectors/AdminSelector";
import {getUserLogsAction} from "../../../redux/actions/AdminActions";

const Logs = ({statsModal, setStatsModal, userId, loadUserLogsData, userLogsData}) => {
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [trackSort, setTrackSort] = useState(0);
    const [Loader, setLoader] = useState(false);

    useEffect(() => {
        if (userId) onSubmit();
    }, [userId, query, sort])

    const onSubmit = (page = 1) => {
        const data = {
            "user_id": userId,
            "filter_options": {
                ...query,
                "sort_array": [sort]
            },
            "pagination": {
                "page": page,
                "size": 10
            }
        }
        setLoader(true);
        loadUserLogsData(data, () => setLoader(false))
    }

    return (
        <>
            <LogsFilter query={query} setQuery={setQuery}/>
            <Card className="mb-2">
                <CardBody>
                    <div>
                        <div>
                            <h4>Logs</h4>
                        </div>
                        <hr/>
                    </div>
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    // setSort((prev) => {
                                    //     return prev[0] === "user_name" && prev[1] === "ASC"
                                    //         ? ["user_name", "DESC"]
                                    //         : ["user_name", "ASC"];
                                    // });
                                    // setTrackSort(1);
                                }}
                            >
                                NAME
                                <span className="ml-1">
                                    {trackSort === 1 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "model_name" && prev[1] === "ASC"
                                            ? ["model_name", "DESC"]
                                            : ["model_name", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                MODEL NAME
                                <span className="ml-1">
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "action" && prev[1] === "ASC"
                                            ? ["action", "DESC"]
                                            : ["action", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                ACTION
                                <span className="ml-1">
                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "url" && prev[1] === "ASC"
                                            ? ["url", "DESC"]
                                            : ["url", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                URL
                                <span className="ml-1">
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "createdAt" && prev[1] === "ASC"
                                            ? ["createdAt", "DESC"]
                                            : ["createdAt", "ASC"];
                                    });
                                    setTrackSort(5);
                                }}
                            >
                                CREATED AT
                                <span className="ml-1">
                                    {trackSort === 5 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            {/*<th>*/}
                            {/*    ACTION*/}
                            {/*</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Loader ?
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={5}>
                                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                                    </td>
                                </tr>
                                : userLogsData && userLogsData?.data?.map((item, key) => {
                                    return (
                                        <tr key={item?.id}>
                                            <td>{item?.User?.first_name} {item?.User?.last_name}</td>
                                            <td>{item?.model_name}</td>
                                            <td>{item?.action}</td>
                                            <td>{item?.url}</td>
                                            <td>{item?.createdAt ? moment(item?.createdAt).format("MMM DD, YYYY") : "N/A"}</td>
                                            {/*<td>*/}
                                            {/*    <UncontrolledDropdown>*/}
                                            {/*        <DropdownToggle href="#" className="card-drop" tag="i">*/}
                                            {/*            <i className="mdi mdi-dots-horizontal font-size-18"/>*/}
                                            {/*        </DropdownToggle>*/}
                                            {/*        /!*<DropdownMenu right>*!/*/}
                                            {/*        /!*    <DropdownItem className="text-primary"*!/*/}
                                            {/*        /!*                  onClick={() => setStatsModal(true)}>*!/*/}
                                            {/*        /!*        View State*!/*/}
                                            {/*        /!*    </DropdownItem>*!/*/}
                                            {/*        /!*</DropdownMenu>*!/*/}
                                            {/*    </UncontrolledDropdown>*/}
                                            {/*</td>*/}
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-center">
                <Pagination
                    pages={userLogsData?.totalPages}
                    page={userLogsData?.currentPage}
                    setPage={(page) => onSubmit(page)}
                    count={userLogsData?.totalItems}
                />
            </div>
        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadUserLogsData: (data, cb) => dispatch(getUserLogsAction(data, cb))
    }
}

const mapStateToProp = (state) => {
    return {
        userLogsData: getSingleUserLogsSelector(state)
    }
}

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Logs))

