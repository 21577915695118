import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import Auth from "../reducers/AuthReducers"
import Admin from "../reducers/AdminReducers"
import thunk from "redux-thunk"

const reducer = combineReducers({
  Auth,
  Admin,
})

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)))

export default store
