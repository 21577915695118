import React, {Fragment, useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import pic1 from "../../../images/default.svg";
import {Row, Col, Card, Table} from "react-bootstrap";
import {
    getBillingDashboardSelector,
} from "../../../redux/selectors/AdminSelector";
import {
    getBillingDashboardAction,
} from "../../../redux/actions/AdminActions";
import {connect} from "react-redux";
import InvoiceFilter from "./InvoiceFilter";
import Pagination from "../common/Pagination/Pagination";
import moment from "moment";


const InvoiceTable = (props) => {
    const {
        getBillingDashboard,
        loadBillingDashboard
    } = props;
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "ASC"]);
    const [trackSort, setTrackSort] = useState(0);

    useEffect(() => {
    }, [query, sort]);

    const onSubmit = (page = 1) => {
        //TODO: filter abject here. filter are already set and are using query variable


        // loadBillingDashboard();
    }

    console.log("INVOICE DATA:", getBillingDashboard)
    return (
        <Fragment>
            <div className="col-12">
                <InvoiceFilter query={query} setQuery={setQuery}/>
                <Card className="mb-2">
                    <Card.Header>
                        <h5 className="card-title">Invoices</h5>
                    </Card.Header>
                    <Card.Body>
                        <Table className="table-hover text-dark">
                            <thead>
                            <tr>
                                <th></th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "user_name" && prev[1] === "ASC"
                                                ? ["user_name", "DESC"]
                                                : ["user_name", "ASC"];
                                        });
                                        setTrackSort(1);
                                    }}
                                >
                                    User Name
                                    <span className="ml-1">
                                        {trackSort === 1 ? (
                                            sort[1] === "DESC" ? (
                                                <i className="fa fa-arrow-down"/>
                                            ) : (
                                                <i className="fa fa-arrow-up"/>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "amount" && prev[1] === "ASC"
                                                ? ["amount", "DESC"]
                                                : ["amount", "ASC"];
                                        });
                                        setTrackSort(2);
                                    }}
                                >
                                    amount
                                    <span className="ml-1">
                                        {trackSort === 2 ? (
                                            sort[1] === "DESC" ? (
                                                <i className="fa fa-arrow-down"/>
                                            ) : (
                                                <i className="fa fa-arrow-up"/>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "paid_date" && prev[1] === "ASC"
                                                ? ["paid_date", "DESC"]
                                                : ["paid_date", "ASC"];
                                        });
                                        setTrackSort(3);
                                    }}
                                >
                                    Payment Date
                                    <span className="ml-1">
                                        {trackSort === 3 ? (
                                            sort[1] === "DESC" ? (
                                                <i className="fa fa-arrow-down"/>
                                            ) : (
                                                <i className="fa fa-arrow-up"/>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="positions">
                            {
                                getBillingDashboard && getBillingDashboard?.invoices?.map((v, i) => {
                                    return (<tr>
                                        <td style={{width: "36px"}}>
                                            <img
                                                className="rounded-circle"
                                                width="35"
                                                src={pic1}
                                                alt=""
                                            />
                                        </td>
                                        <td>{v.company_name}</td>
                                        <td>{v.amount_paid}</td>
                                        <td>{v.date_paid ? moment(v.date_paid).format("MMM DD, YYYY") : "N/A"}</td>
                                    </tr>)
                                })
                            }

                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <div className="d-flex justify-content-center mb-2">
                    <Pagination
                        pages={1}
                        page={1}
                        // setPage={(page) => onSubmit(page)}
                        setPage={(page) => onSubmit(page)}
                        count={3}
                    />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        getBillingDashboard: getBillingDashboardSelector(state),
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        loadBillingDashboard: () => dispatch(getBillingDashboardAction()),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceTable));

