export const getCompaniesSelector = (state) => {
    if (state?.Admin?.Companies) return state.Admin.Companies
    return false
}
export const getPlansSelector = (state) => {
    if (state?.Admin?.Plans) return state.Admin.Plans
    return false
}
export const getCompanyDataLoadingSelector = (state) => {
    if (state?.Admin?.CompanyDataLoading) return state.Admin.CompanyDataLoading
    return false
}
export const getUsersSelector = (state) => {
    if (state?.Admin?.Users) return state.Admin.Users
    return false
}
export const getUserDataLoadingSelector = (state) => {
    if (state?.Admin?.UserDataLoading) return state.Admin.UserDataLoading
    return false
}
export const getRolesSelector = (state) => {
    if (state?.Admin?.Roles) return state.Admin.Roles
    return false
}

export const getAnalyticalDashboardSelector = (state) => {
    if (state?.Admin?.AnalyticalDashboard) return state.Admin.AnalyticalDashboard
    return false
}

export const getAnalyticalEmployerRegistrationDashboardSelector = (state) => {
    if (state?.Admin?.AnalyticalEmployerRegistrationDashboard) return state.Admin.AnalyticalEmployerRegistrationDashboard
    return false
}

export const getAnalyticalCandidateRegistrationDashboardSelector = (state) => {
    if (state?.Admin?.AnalyticalCandidateRegistrationDashboard) return state.Admin.AnalyticalCandidateRegistrationDashboard
    return false
}


export const getBillingDashboardSelector = (state) => {
    if (state?.Admin?.BillingDashboard) return state.Admin.BillingDashboard
    return false
}

export const getBillingInvoiceGenerationDashboardSelector = (state) => {
    if (state?.Admin?.BillingInvoiceGenerationDashboard) return state.Admin.BillingInvoiceGenerationDashboard
    return false
}

export const getCompanyProfileSelector = (state) => {
    if (state?.Admin?.CompanyPageData?.profile) return state.Admin.CompanyPageData.profile
    return false
}

export const getCompanyPositionsSelector = (state) => {
    if (state?.Admin?.CompanyPageData?.positions) return state.Admin.CompanyPageData.positions
    return false
}

export const getCompanyTeamMembersSelector = (state) => {
    if (state?.Admin?.CompanyPageData?.team_members) return state.Admin.CompanyPageData.team_members
    return false
}

export const getCompanyLogsSelector = (state) => {
    if (state?.Admin?.CompanyPageData?.logs) return state.Admin.CompanyPageData.logs
    return false
}

export const getCompanySubscriptionsSelector = (state) => {
    if (state?.Admin?.CompanyPageData?.subscription) return state.Admin.CompanyPageData.subscription
    return false
}

export const getSingleUserDetailSelector = (state) => {
    if (state?.Admin?.userDetail?.data?.User) return state.Admin.userDetail.data.User
    return false
}

export const getSingleEmployerDetailSelector = (state) => {
    if (state?.Admin?.userDetail?.data) return state.Admin.userDetail.data
    return false
}

export const getSingleUserInterviewDetailSelector = (state) => {
    if (state?.Admin?.userDetail?.data?.InterviewStats) return state.Admin.userDetail.data.InterviewStats
    return false
}
export const getSingleUserProfileDetailSelector = (state) => {
    if (state?.Admin?.userDetail?.data?.Profile) return state.Admin.userDetail.data.Profile
    return false
}

export const getSingleUserInterviewsSelector = (state) => {
    if (state?.Admin?.userDetail?.Interviews?.Interviews) return state.Admin.userDetail.Interviews.Interviews
    return false
}


export const getSingleUserLogsSelector = (state) => {
    if (state?.Admin?.userDetail?.Logs?.Logs) return state.Admin.userDetail.Logs.Logs
    return false
}


export const getSupportMembersSelector = (state) => {
    if (state?.Admin?.SupportMembers) return state.Admin.SupportMembers
    return false
}


export const getCompanyListSelector = (state) => {
    if (state?.Admin?.CompaniesList) return state.Admin.CompaniesList
    return false
}


export const getActiveUsersSelector = (state) => {
    if (state?.Admin?.ActiveUsers) return state.Admin.ActiveUsers
    return false
}


