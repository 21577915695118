import {lazy, Suspense, useEffect} from "react"
import store from "./redux/store/Store"
/// Components
import Index from "./jsx"
import { connect, Provider, useDispatch } from "react-redux"
import { Route, Switch, withRouter, useHistory  } from "react-router-dom"
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
import "react-toastify/dist/ReactToastify.css"
import "./css/main.css"

import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated, isVerified } from "./redux/selectors/AuthSelector";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500)
  })
})

const App = ( props ) => {
  // const history = useHistory();
  const dispatch = useDispatch()
  useEffect(() => {
    checkAutoLogin(dispatch, props.history)
  }, [dispatch, props.history])

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
    </Switch>
  )

  if(props.isAuthenticated && props.isVerified){

    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index />
      </Suspense>
    )
  } else {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {routes}
      </Suspense>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isVerified: isVerified(state),
  }
}

export default withRouter(connect(mapStateToProps)(App))
