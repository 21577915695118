import axiosInstance from "./AxiosInstance"
import Axios from "axios"

export const getCompaniesService = (postData) => {
    console.log("API DATA: ", postData)
    return axiosInstance.post(`/admin/companies`, postData)
}

export const getUsersService = (postData) => {
    return axiosInstance.post(`/admin/users/`, postData)
}

export const getUserDetailService = (postData) => {
    return axiosInstance.get(`/admin/candidate/${postData.user_id}`, postData)
}

export const userChangeActiveStatusService = (postData) => {
    return axiosInstance.post(`/admin/users/status`, postData)
}

export const userPasswordResetService = (postData) => {
    return axiosInstance.post(`/admin/users/reset`, postData)
}

export const getUEmployerDetailService = (postData) => {
    return axiosInstance.get(`/admin/employer/${postData.user_id}`, postData)
}

export const getPlansService = () => {
    return axiosInstance.get(`/company/subscription/plans`)
}

export const getRolesService = () => {
    return axiosInstance.get(`/admin/users/roles`)
}

export const getAnalyticalDashboardService = () => {
    return axiosInstance.get(`/admin/`)
}

export const getAnalyticalEmployerRegistrationsDashboardService = (data) => {
    return axiosInstance.post(`/admin/employers`, data)
}

export const getAnalyticalCandidateRegistrationsDashboardService = (data) => {
    return axiosInstance.post(`/admin/candidates`, data)
}

export const getBillingDashboardService = () => {
    return axiosInstance.get(`/admin/billing`)
}
export const getActiveUsersService = () => {
    return axiosInstance.get(`/admin/active`)
}

export const getBillingInvoiceGenerationDashboardService = (data) => {
    return axiosInstance.post(`/admin/billing/invoice`, data)
}

export const getUserForgotPasswordRequestsService = (data) => {
    /**
     * data: {
     *     "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     * }
     */

    return axiosInstance.post(`/admin/users/forgot/request`, data)
}
export const postApproveUserForgotPasswordRequestService = (data) => {
    /**
     * data: {
     *     forgot_password_request_id: ""
     * }
     */

    return axiosInstance.post(`/admin/users/forgot/request/approve`, data)
}

export const getCompanyProfileService = (data) => {
    /**
     *
     *      data={
     *          company_id: ""
     *      }
     *
     *
     * */
    return axiosInstance.get(`/admin/companies/${data.company_id}`, data)
}

export const getCompanyTeamMembersService = (data) => {
    /**
     * data = {
     *             "company_id": "",
     *             "filter_options": {
     *                 "member_name": "ec",
     *                 "member_email": "email@email.com",
     *                 "member_phone": "",
     *                 "member_role": ""
     *                 "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     *         }
     *
     * */
    return axiosInstance.post(`/admin/companies/${data.company_id}/members`, data)
}

export const getCompanyPositionsService = (data) => {
    /**
     * data = {
     *             "company_id": "",
     *             "filter_options": {
     *                 "position_title": "ec",
     *                 "position_closing_date": "email@email.com",
     *                 "user_email": "",
     *                 "sort_array": [ ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     *         }
     *
     * */
    return axiosInstance.post(`/admin/companies/${data.company_id}/positions`, data)
}

export const getCompanySubscriptionsService = (data) => {
    /**
     * data = {
     *             "company_id": "",
     *             "filter_options": {
     *                 "subscription_name": "ec",
     *                 "subscription_amount": "email@email.com",
     *                 "subscription_paid_date": "",
     *                 "subscription_created_at": ""
     *                 "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     *         }
     *
     * */
    return axiosInstance.post(`/admin/companies/${data.company_id}/subscriptions`, data)
}
export const getCompanyLogsService = (data) => {
    /**
     * data = {
     *             "company_id": "",
     *             "filter_options": {
     *                 "user_name": "ec",
     *                 "model": "email@email.com",
     *                 "url": "",
     *                 "created_at": ""
     *                 "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     *         }
     *
     * */
    return axiosInstance.post(`/admin/companies/${data.company_id}/logs`, data)
}

export const updateCompanySubscriptionService = (data) => {
    /**
     * data = {
     *             "subscription": {
     *                 "name": "custom", // default: "custom"
     *                 "users_limit": 20,
     *                 "position_limit": 20,
     *                 "interview_limit": 20,
     *                 "amount": 20,
     *                 "expiry_date": date
     *             },
     *             "company_id": ""
     *
     * }
     *
     * */
    return axiosInstance.post(`/admin/companies/subscription`, data)
}
export const deleteCompanyMemberService = (data) => {
    /**
     *
     * data ={
     *      company_id : "",
     *      company_member_id :"",
     *      mem_id:""
     * }
     *
     * */
    return axiosInstance.delete(`/admin/companies/member`, {data: data})
}
export const deleteCompanyPositionService = (data) => {
    /**
     *
     * data ={
     *      company_id : "",
     *      position_id :""
     * }
     *
     * */
    return axiosInstance.delete(`/admin/companies/position`, {data: data})
}
export const updateCompanyStatusService = (data) => {
    /**
     * data = {
     *             "status": true // true, false
     *             "company_id": ""
     *
     * }
     *
     * */
    return axiosInstance.post(`/admin/companies/status`, data)
}

export const addCompanyMemberService = (data) => {
    /**
     *
     * company_id: "",
     *   data = {
     *     user_data: {
     *         "first_name": "",
     *         "last_name": "",
     *         "phone": "",
     *         "email": "",
     *         "role": ""
     *                 }
     *          }
     *
     * */
    return axiosInstance.post(`/admin/companies/member`, data)
}

export const addCandidateInterviewService = (data) => {
    /**
     *
     * const data ={
     *     position_url: "",
     *     candidate_id: ""
     * }
     *
     * */
    return axiosInstance.post(`/admin/candidate/interview`, data)
}

export const deleteCandidateInterviewService = (data) => {
    /**
     *
     * const data ={
     *     position_url: "",
     *     candidate_id: ""
     * }
     *
     * */
    return axiosInstance.delete(`/admin/candidate/interview`, {data: data})
}

export const getCandidateInterviewsService = (data) => {
    /**
     *
     * const data = {
     *     "filter_options": {
     *         "name": "",
     *         "position": "",
     *         "status": "",
     *         "tag": "",
     *         "response": "",
     *         "rating": "",
     *         "team_member": "",
     *         "complete_date": "",
     *         "added_date": "",
     *         "sort_array": [["id", "DESC"], ["createdAt", "ASC"]]
     *     },
     *     "pagination": {
     *         "page": 1,
     *         "size": 3
     *     },
     *     user_id: ""
     * }
     *
     * */
    console.log("Interviews Data: ", data)
    return axiosInstance.post(`/admin/candidate/interviews`, data)
}

export const getUserLogsService = (data) => {
    /**
     *
     * const data = {
     *             "filter_options": {
     *                 "user_name": "ec",
     *                 "model": "email@email.com",
     *                 "url": "",
     *                 "created_at": ""
     *                 "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             },
     *             user_id: ""
     *         }
     *
     * */
    console.log("Interviews Data: ", data)
    return axiosInstance.post(`/admin/users/${data.user_id}/logs`, data)
}

export const updateUserProfileService = (data) => {
    /**
     *
     * const data = {
     *             user_data: {
     *                 first_name: "",
     *                 last_name: "",
     *                 phone: ""
     *             }
     *             user_id: ""
     *         }
     *
     * */
    return axiosInstance.post(`/admin/users/profile`, data)
}

// Support

export const getSupportMembersService = (data) => {
    /**
     *
     * const data = {
     *             "filter_options": {
     *                 "user_name": "ec",
     *                 "model": "email@email.com",
     *                 "url": "",
     *                 "created_at": ""
     *                 "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
     *             },
     *             "pagination": {
     *                 "page": 1,
     *                 "size": 3
     *             }
     *         }
     *
     * */
    return axiosInstance.post(`/admin/support/members`, data)
}


export const addSupportMemberService = (data) => {
    /**
     *
     * const data = {
     *             "user_data": {
     *         "first_name": "Support",
     *         "last_name": "DigitalHire",
     *         "phone": "01234567893",
     *         "email": "supportbequest@digitalhire.com"
     *     }
     *
     * */
    return axiosInstance.post(`/admin/support/member`, data)
}


export const createCompanySupportMemberService = (data) => {
    /**
     *
     * const data = {
     *     "company_id": "620bd62d8c2ac373441e88a3",
     *     "user_id": "fc4e7391-43ba-417f-92f0-5caaef6802ba",
     *     "expire_date": "2022-10-25"
     * }
     *
     * */
    return axiosInstance.post(`/admin/support/company/member`, data)
}

export const getCompaniesListService = (data) => {

    return axiosInstance.get(`/admin/support/companies`)
}


export const postMediaToAWSSignedService = (data) => {
    return axiosInstance.post(`/media/put-signing-url`, {
        media_key: data,
    })
}

export const postMediaToAWSUploadService = (submitURL, file) => {
    return Axios.put(`${submitURL}`, file)
}


export const setProfilePdfResumeService = (data) => {
    return axiosInstance.post(`admin/candidate/profile`, {pdf_resume: data.key, user_id: data.user_id})
}


export const removeProfilePdfResumeService = () => {
    return axiosInstance.post(`admin/candidate/profile`, {pdf_resume: ""})
}

