import React, {useEffect, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    ModalFooter,
    Input,
    Form,
    FormGroup,
    Label,
} from "reactstrap"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {Modal} from "react-bootstrap"
import avatar from "../../../images/default.svg"
import Switch from "../common/Switch"
import ImageCropModal from "../common/ImageCropModal"
import Loader from "../common/Loader/Loader"
import {updateUserProfileAction} from "../../../redux/actions/AdminActions";

const EditSubscription = ({subscription, updateSubscription, toggle, companyId}) => {
    let errorsObj = {userL: "", interviewL: "", positionL: "", sName: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [userUsedLimit, setUserUsedLimit] = useState("")
    const [userAllowedLimit, setUserAllowedLimit] = useState("")

    const [interviewUsedLimit, setInterviewUsedLimit] = useState("")
    const [interviewAllowedLimit, setInterviewAllowedLimit] = useState("")

    const [positionUsedLimit, setPositionUsedLimit] = useState("")
    const [positionAllowedLimit, setPositionAllowedLimit] = useState("")
    const [subName, setSubName] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (subscription?.limit) {
            const l = JSON.parse(subscription?.limit)

            setInterviewUsedLimit(l?.interviews?.used)
            setInterviewAllowedLimit(l?.interviews?.allowed)

            setPositionUsedLimit(l?.positions?.used)
            setPositionAllowedLimit(l?.positions?.allowed)

            setUserUsedLimit(l?.users?.used)
            setUserAllowedLimit(l?.users?.allowed)
            setSubName(subscription?.name)
        }


    }, [subscription])


    function onSubmit(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (userAllowedLimit === "") {
            errorObj.userL = "User limit is Required"
            error = true
        }
        if (interviewAllowedLimit === "") {
            errorObj.interviewL = "Interview limit is Required"
            error = true
        }
        if (positionAllowedLimit === "") {
            errorObj.positionL = "Position limit is Required"
            error = true
        }
        if (positionAllowedLimit === "") {
            errorObj.positionL = "Position limit is Required"
            error = true
        }

        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            "subscription": {
                "name": subName ? subName : "custom", // default: "custom"
                "users_limit": userAllowedLimit,
                "position_limit": positionAllowedLimit,
                "interview_limit": interviewAllowedLimit,
                // "amount": 20,
                // "expiry_date": date
            },
            "company_id": companyId
        }
        updateSubscription(data, () => {
            toggle()
        })

        // props.uploadMediaToAWS({ media_key: img, file: uploadFile }, props, () => {
        //   props.updateUserProfile(data, props.history, () => {
        //     props.showAlert()
        //     setImg(undefined)
        //     setIsLoading(false)
        //   })
        // })
    }

    return (
        <>
            <Row>
                {/* <Col lg={6}> */}
                {/* <Card> */}
                <CardBody>
                    <form onSubmit={onSubmit} className=" col-12 ">
                        <h4>Change Subscription</h4>
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Name {" "}
                                        <span className="text-muted">
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-fname1"
                                        placeholder="Subscription Name "
                                        onChange={(e) => setSubName(e.target.value)}
                                        value={subName}
                                    />
                                    {errors.sName &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.sName}</div>}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        User Limit{" "}
                                        <span className="text-muted">
                          <small>Used: {userUsedLimit}</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-fname1"
                                        placeholder="User limit "
                                        onChange={(e) => setUserAllowedLimit(e.target.value)}
                                        value={userAllowedLimit}
                                    />
                                    {errors.userL &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.userL}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Position Limit{" "}
                                        <span className="text-muted">
                          <small>Used: {positionUsedLimit}</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-lname1"
                                        placeholder="Position limit"
                                        onChange={(e) => setPositionAllowedLimit(e.target.value)}
                                        value={positionAllowedLimit}
                                    />
                                    {errors.positionL &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.positionL}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Interview Limit{" "}
                                        <span className="text-muted">
                          <small>Used: {interviewUsedLimit}</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-email1"
                                        placeholder="Interview limit"
                                        onChange={(e) => setInterviewAllowedLimit(e.target.value)}
                                        value={interviewAllowedLimit}
                                        // disabled
                                    />
                                    {errors.interviewL &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.interviewL}</div>}
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-block col-4"
                            // disabled={isSubmitting}
                            onClick={() => {

                                setIsLoading(true)
                            }}
                        >
                            {isLoading ? <Loader spinnerClass="text-white"/> : "Save changes"}
                        </button>
                    </form>
                    {/* <div className="d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <h4>Basic Information</h4>
                      <hr />
                    </div>
                    <div>
                      <img src={avatar} alt="" />
                      <h5 className="mt-4">3MP Atlanta</h5>
                      <span className="d-block mb-1">
                        <i className="bx bx-phone-call"></i>&nbsp;923145144956
                      </span>
                      <span className="d-block mb-1">
                        <i className="bx bx-mail-send"></i>&nbsp;admin@3mp.com
                      </span>
                      <span className="d-block mb-1">
                        <i className="mdi mdi-calendar-blank green mr-2"></i>2021-02-24
                      </span>
                    </div>
                  </div> */}
                </CardBody>
                {/* </Card> */}
                {/* </Col> */}
            </Row>


        </>
    )
}

export default EditSubscription
