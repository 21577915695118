import React, {useEffect, useState} from "react"
import {
    Card,
    CardBody,
    Table,
} from "reactstrap"
import SubscriptionFilter from "./SubscriptionFilter";
import Pagination from "../common/Pagination/Pagination";
import {getCompanyProfileAction, getCompanySubscriptionsAction} from "../../../redux/actions/AdminActions";
import {getCompanyProfileSelector, getCompanySubscriptionsSelector} from "../../../redux/selectors/AdminSelector";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";

const Subscription = (props) => {
    const {companyId, loadCompanySubscriptionData, companySubscriptionData} = props
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [trackSort, setTrackSort] = useState(0);

    useEffect(() => {
        if (companyId) onSubmit();
    }, [companyId, query, sort])

    const onSubmit = (page = 1) => {
        const data = {
            "company_id": companyId,
            "filter_options": {
                ...query,
                "sort_array": [sort]
            },
            "pagination": {
                "page": page,
                "size": 10
            }
        }
        loadCompanySubscriptionData(data)
    }

    return (
        <>
            <SubscriptionFilter query={query} setQuery={setQuery}/>
            <Card className="mb-2">
                <CardBody>
                    <div>
                        <div>
                            <h4>Subscription History</h4>
                        </div>
                        <hr/>
                    </div>
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "subscription_name" && prev[1] === "ASC"
                                            ? ["subscription_name", "DESC"]
                                            : ["subscription_name", "ASC"];
                                    });
                                    setTrackSort(1);
                                }}
                            >
                                Subscription
                                <span className="ml-1">
                                    {trackSort === 1 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "subscription_amount" && prev[1] === "ASC"
                                            ? ["subscription_amount", "DESC"]
                                            : ["subscription_amount", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                Amount
                                <span className="ml-1">
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "subscription_paid_date" && prev[1] === "ASC"
                                            ? ["subscription_paid_date", "DESC"]
                                            : ["subscription_paid_date", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                Paid Date
                                <span className="ml-1">
                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "subscription_expiry_date" && prev[1] === "ASC"
                                            ? ["subscription_expiry_date", "DESC"]
                                            : ["subscription_expiry_date", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                Expiry
                                <span className="ml-1">
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {companySubscriptionData?.Subscriptions?.rows?.map((item, key) => {
                                return (
                                    <tr key={`company-detail-subscription-${item.id}`}>
                                        <td>{item?.name}</td>
                                        <td>{item?.amount || '$0'}</td>
                                        <td>{item?.from_date ? moment(item?.from_date).format("MMM DD, YYYY") : "N/A"}</td>
                                        <td>{item?.to_date ? moment(item?.to_date).format("MMM DD, YYYY") : "N/A"}</td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-center mb-4">
                <Pagination
                    pages={1}
                    page={1}
                    setPage={(page) => onSubmit(page)}
                    count={3}
                />
            </div>
        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanySubscriptionData: (data) => dispatch(getCompanySubscriptionsAction(data))
    }
}

const mapStateToProp = (state) => {
    return {
        companySubscriptionData: getCompanySubscriptionsSelector(state)
    }
}

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Subscription))

