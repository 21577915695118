export const GET_COMPANY_API_REQUEST = "GET_COMPANY_API_REQUEST"
export const GET_COMPANY_API_SUCCESS = "GET_COMPANY_API_SUCCESS"
export const GET_COMPANY_API_FAILURE = "GET_COMPANY_API_FAILURE"
export const GET_USERS_API_REQUEST = "GET_USERS_API_REQUEST"
export const GET_USERS_API_SUCCESS = "GET_USERS_API_SUCCESS"
export const GET_USERS_API_FAILURE = "GET_USERS_API_FAILURE"
export const GET_USER_DETAIL_API_REQUEST = "GET_USER_DETAIL_API_REQUEST"
export const GET_USER_DETAIL_API_SUCCESS = "GET_USER_DETAIL_API_SUCCESS"
export const GET_USER_DETAIL_API_FAILURE = "GET_USER_DETAIL_API_FAILURE"
export const GET_EMPLOYER_DETAIL_API_REQUEST = "GET_EMPLOYER_DETAIL_API_REQUEST"
export const GET_EMPLOYER_DETAIL_API_SUCCESS = "GET_EMPLOYER_DETAIL_API_SUCCESS"
export const GET_EMPLOYER_DETAIL_API_FAILURE = "GET_EMPLOYER_DETAIL_API_FAILURE"

export const USER_DEACTIVATE_API_REQUEST = "USER_DEACTIVATE_API_REQUEST"
export const USER_DEACTIVATE_API_SUCCESS = "USER_DEACTIVATE_API_SUCCESS"
export const USER_DEACTIVATE_API_FAILURE = "USER_DEACTIVATE_API_FAILURE"

export const USER_PASSWORD_RESET_API_REQUEST = "USER_PASSWORD_RESET_API_REQUEST"
export const USER_PASSWORD_RESET_API_SUCCESS = "USER_PASSWORD_RESET_API_SUCCESS"
export const USER_PASSWORD_RESET_API_FAILURE = "USER_PASSWORD_RESET_API_FAILURE"

export const GET_PLANS_API_REQUEST = "GET_PLANS_API_REQUEST"
export const GET_PLANS_API_SUCCESS = "GET_PLANS_API_SUCCESS"
export const GET_PLANS_API_FAILURE = "GET_PLANS_API_FAILURE"
export const GET_ROLES_API_REQUEST = "GET_ROLES_API_REQUEST"
export const GET_ROLES_API_SUCCESS = "GET_ROLES_API_SUCCESS"
export const GET_ROLES_API_FAILURE = "GET_ROLES_API_FAILURE"
export const GET_ANALYTICAL_DASHBOARD_API_SUCCESS = "GET_ANALYTICAL_DASHBOARD_API_SUCCESS"
export const GET_ANALYTICAL_DASHBOARD_API_FAILURE = "GET_ANALYTICAL_DASHBOARD_API_FAILURE"
export const GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS = "GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS"
export const GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE = "GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE"
export const GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS = "GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS"
export const GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE = "GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE"

export const GET_BILLING_DASHBOARD_API_SUCCESS = "GET_BILLING_DASHBOARD_API_SUCCESS"
export const GET_BILLING_DASHBOARD_API_FAILURE = "GET_BILLING_DASHBOARD_API_FAILURE"
export const GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS = "GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS"
export const GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE = "GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE"


export const GET_COMPANY_PROFILE_API_SUCCESS = "GET_COMPANY_PROFILE_API_SUCCESS"
export const GET_COMPANY_PROFILE_API_FAILURE = "GET_COMPANY_PROFILE_API_FAILURE"

export const GET_COMPANY_TEAM_MEMBERS_API_SUCCESS = "GET_COMPANY_TEAM_MEMBERS_API_SUCCESS"
export const GET_COMPANY_TEAM_MEMBERS_API_FAILURE = "GET_COMPANY_TEAM_MEMBERS_API_FAILURE"

export const GET_COMPANY_POSITIONS_API_SUCCESS = "GET_COMPANY_POSITIONS_API_SUCCESS"
export const GET_COMPANY_POSITIONS_API_FAILURE = "GET_COMPANY_POSITIONS_API_FAILURE"

export const GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS = "GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS"
export const GET_COMPANY_SUBSCRIPTIONS_API_FAILURE = "GET_COMPANY_SUBSCRIPTIONS_API_FAILURE"

export const UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS = "UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS"
export const UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE = "UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE"

export const UPDATE_COMPANY_STATUS_API_SUCCESS = "UPDATE_COMPANY_STATUS_API_SUCCESS"
export const UPDATE_COMPANY_STATUS_API_FAILURE = "UPDATE_COMPANY_STATUS_API_FAILURE"

export const GET_COMPANY_LOGS_API_SUCCESS = "GET_COMPANY_LOGS_API_SUCCESS"
export const GET_COMPANY_LOGS_API_FAILURE = "GET_COMPANY_LOGS_API_FAILURE"

export const ADD_COMPANY_TEAM_MEMBER_API_SUCCESS = "ADD_COMPANY_TEAM_MEMBER_API_SUCCESS"
export const ADD_COMPANY_TEAM_MEMBER_API_FAILURE = "ADD_COMPANY_TEAM_MEMBER_API_FAILURE"

export const DELETE_COMPANY_TEAM_MEMBER_API_SUCCESS = "DELETE_COMPANY_TEAM_MEMBER_API_SUCCESS"
export const DELETE_COMPANY_TEAM_MEMBER_API_FAILURE = "DELETE_COMPANY_TEAM_MEMBER_API_FAILURE"

export const DELETE_COMPANY_POSITION_API_SUCCESS = "DELETE_COMPANY_POSITION_API_SUCCESS"
export const DELETE_COMPANY_POSITION_API_FAILURE = "DELETE_COMPANY_POSITION_API_FAILURE"

export const ADD_CANDIDATE_INTERVIEW_API_SUCCESS = "ADD_CANDIDATE_INTERVIEW_API_SUCCESS"
export const ADD_CANDIDATE_INTERVIEW_API_FAILURE = "ADD_CANDIDATE_INTERVIEW_API_FAILURE"

export const GET_CANDIDATE_INTERVIEWS_API_SUCCESS = "GET_CANDIDATE_INTERVIEWS_API_SUCCESS"
export const GET_CANDIDATE_INTERVIEWS_API_FAILURE = "GET_CANDIDATE_INTERVIEWS_API_FAILURE"

export const GET_USER_LOGS_API_SUCCESS = "GET_USER_LOGS_API_SUCCESS"
export const GET_USER_LOGS_API_FAILURE = "GET_USER_LOGS_API_FAILURE"

export const GET_ACTIVE_USERS_API_SUCCESS = "GET_ACTIVE_USERS_API_SUCCESS"
export const GET_ACTIVE_USERS_API_FAILURE = "GET_ACTIVE_USERS_API_FAILURE"
export const GET_ACTIVE_USERS_API_REQUEST = "GET_ACTIVE_USERS_API_REQUEST"

export const DELETE_CANDIDATE_INTERVIEW_API_REQUEST = "DELETE_CANDIDATE_INTERVIEW_API_REQUEST"
export const DELETE_CANDIDATE_INTERVIEW_API_SUCCESS = "DELETE_CANDIDATE_INTERVIEW_API_SUCCESS"
export const DELETE_CANDIDATE_INTERVIEW_API_FAILURE = "DELETE_CANDIDATE_INTERVIEW_API_FAILURE"

export const UPDATE_USER_PROFILE_API_REQUEST = "UPDATE_USER_PROFILE_API_REQUEST"
export const UPDATE_USER_PROFILE_API_SUCCESS = "UPDATE_USER_PROFILE_API_SUCCESS"
export const UPDATE_USER_PROFILE_API_FAILURE = "UPDATE_USER_PROFILE_API_FAILURE"


// Support


export const SUPPORT_MEMBERS_API_REQUEST = "SUPPORT_MEMBERS_API_REQUEST"
export const SUPPORT_MEMBERS_API_SUCCESS = "SUPPORT_MEMBERS_API_SUCCESS"
export const SUPPORT_MEMBERS_API_FAILURE = "SUPPORT_MEMBERS_API_FAILURE"

export const ADD_SUPPORT_MEMBERS_API_REQUEST = "ADD_SUPPORT_MEMBERS_API_REQUEST"
export const ADD_SUPPORT_MEMBERS_API_SUCCESS = "ADD_SUPPORT_MEMBERS_API_SUCCESS"
export const ADD_SUPPORT_MEMBERS_API_FAILURE = "ADD_SUPPORT_MEMBERS_API_FAILURE"

export const ADD_COMPANY_SUPPORT_MEMBERS_API_REQUEST = "ADD_COMPANY_SUPPORT_MEMBERS_API_REQUEST"
export const ADD_COMPANY_SUPPORT_MEMBERS_API_SUCCESS = "ADD_COMPANY_SUPPORT_MEMBERS_API_SUCCESS"
export const ADD_COMPANY_SUPPORT_MEMBERS_API_FAILURE = "ADD_COMPANY_SUPPORT_MEMBERS_API_FAILURE"

export const GET_COMPANY_LIST_API_REQUEST = "GET_COMPANY_LIST_API_REQUEST"
export const GET_COMPANY_LIST_API_SUCCESS = "GET_COMPANY_LIST_API_SUCCESS"
export const GET_COMPANY_LIST_API_FAILURE = "GET_COMPANY_LIST_API_FAILURE"

export const POST_AWS_MEDIA_API_REQUEST = "POST_AWS_MEDIA_API_REQUEST"
export const POST_AWS_MEDIA_API_SUCCESS = "POST_AWS_MEDIA_API_SUCCESS"
export const POST_AWS_MEDIA_API_FAILURE = "POST_AWS_MEDIA_API_FAILURE"
