import React from "react";
import Multistep from "react-multistep";

import { Link } from "react-router-dom";

import StepOne from "./../components/Registration/StepOne";
import StepTwo from "./../components/Registration/StepTwo";
import StepThree from "./../components/Registration/StepThree";
import StepFour from "./../components/Registration/StepFour";

// image
import logo from "../../images/logo-full.png";

const Register = ({ history }) => {
  
  const steps = [
    { name: "Personal Info", component: <StepOne /> },
    { name: "Company Info", component: <StepTwo /> },
    { name: "Business Hours", component: <StepThree /> },
    { name: "Email Setup", component: <StepFour /> },
  ];
  const prevStyle = {
    background: "#F7FAFC",
    borderWidth: "0px",
    color: "#333333",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "0.55em 2em",
    border: "1px solid #EEEEEE",
    marginRight: "1rem",
  };
  const nextStyle = {
    background: "#558b2f",
    borderWidth: "0px",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "0.55em 2em",
  };
  const submitHandler = (e) => {
    e.preventDefault();
    history.push("/");
  };
  return (
    
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-18 col-xxl-8">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Account Setup</h4>
              </div>
              <div className="card-body">
                <div
                  id="step-form-horizontal"
                  className="step-form-horizontal "
                >
                {/* <form
                  onSubmit={(e) => e.preventDefault()}
                  id="step-form-horizontal"
                  className="step-form-horizontal"
                > */}
                  <Multistep
                    showNavigation={true}
                    steps={steps}
                    prevStyle={prevStyle}
                    nextStyle={nextStyle}
                  />
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
