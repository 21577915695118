import React, {useState, useEffect} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
} from "reactstrap"
import Logs from "./Logs"
import {Modal} from "react-bootstrap"
import avatar from "../../../images/default.svg"
import JSONPretty from "react-json-prettify"
import github from "react-json-prettify/dist/themes/googlecode"
import {ReactComponent as ResumeIcon} from "../../../images/svg/documents.svg"
// import PhoneNumber from "../common/PhoneNumber"
import Switch from "../common/Switch"
import DeletePrompt from "../common/DeletePrompt"
import EditEmployerProfile from "./EditEmployerProfile"
import moment from "moment"
import "./userdetails.css"
import {
    getSingleUserDetailSelector,
    getSingleUserInterviewDetailSelector, getSingleUserProfileDetailSelector
} from "../../../redux/selectors/AdminSelector";
import {
    getCandidateInterviewsAction,
    getUserDetailAction,
    getUserLogsAction,
    getEmployerDetailAction
} from "../../../redux/actions/AdminActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const EmployerDetails = (props) => {
    const [editProfile, setEditProfile] = useState(false);
    const [modal, setModal] = useState(false);
    const [statsModal, setStatsModal] = useState(undefined);
    const [userId, setUserId] = useState(String(props?.location?.pathname).split("/employer-detail/")[1])
    const {getEmployerDetail} = props
    const renderRating = (rating) => {
        if (rating === 0 || !rating) return "secondary"
        if (rating === 1 || rating === 2) return "danger"
        if (rating === 3) return "warning"
        if (rating === 4 || rating === 5) return "success"
    }

    useEffect(() => {
        const id = String(props?.location?.pathname).split("/employer-detail/")[1];
        setUserId(id);
        if (id) {
            getEmployerDetail({user_id: id});
        } else {
            props.history.push("/users");
        }

    }, [props?.location?.pathname])


    return (
        <>
            <Row>
                <Col lg={12}>
                    <Card
                        className="profile-data h-unset"
                        style={{
                            borderTop: "4px solid #001c35",
                        }}
                    >
                        <div class="card-header">
                            <h5 class="card-title text-center">Basic Information</h5>
                        </div>

                        <CardBody>
                            <EditEmployerProfile/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Logs statsModal={statsModal} setStatsModal={setStatsModal} userId={userId}/>

            {/*<Logs statsModal={statsModal} setStatsModal={setStatsModal}/>*/}
            {/* <Modal
        centered={true}
        show={editProfile}
        onHide={() => {
          setEditProfile(false)
        }}
      >
        <EditUserProfile />
      </Modal> */}

            <Modal
                centered={true}
                show={modal?.show}
                onHide={() => {
                    setModal(undefined);
                }}
            >
                <DeletePrompt
                    title={`Are you sure you want to ${modal?.title}?`}
                    btnText={modal?.btn}
                    func={() => modal?.fn()}
                    toggle={() => {
                        setModal(undefined);
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                size="lg"
                show={statsModal}
                onHide={() => {
                    setStatsModal(false)
                }}
            >
                <Card>
                    <CardBody>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>URL</th>
                                <th>PARAMETERS</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>/dashboard</td>
                                <td>
                                    <JSONPretty
                                        json={{
                                            company: "b63549e7-3771-4a90-98b9-6047185a4c86",
                                            pagination: {page: 1, size: 10},
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>STATE</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <JSONPretty
                                        json={{
                                            name: "Sadiq Public School",
                                            industry: "Education Management",
                                            logo: "blob:https://employers.digitalhire.net/35a5d67d-34b3-4f4d-9c4e-d83e7c22156f",
                                            email: "robas@yahoo.com",
                                            website: "www.youtube.com",
                                            address: "Address 1",
                                            address2: "Address 2",
                                            city: "Islamabad",
                                            state: "Islamabad",
                                            country: "PK",
                                            postal_code: "46000",
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Users: getUsersSelector(state),
        getCurrentUser: getSingleUserDetailSelector(state),
        getCurrentUserInterview: getSingleUserInterviewDetailSelector(state),
        getCurrentUserProfile: getSingleUserProfileDetailSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEmployerDetail: (data) => dispatch(getEmployerDetailAction(data)),
        getUserInterviews: (data) => dispatch(getCandidateInterviewsAction(data)),
        getUserLogs: (data) => dispatch(getUserLogsAction(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployerDetails))

