import React, {useState, useEffect, useRef} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
} from "reactstrap"
import Interviews from "./Interviews"
import Logs from "./Logs"
import {Modal} from "react-bootstrap"
import avatar from "../../../images/default.svg"
import JSONPretty from "react-json-prettify"
import github from "react-json-prettify/dist/themes/googlecode"
import {ReactComponent as ResumeIcon} from "../../../images/svg/documents.svg"
// import PhoneNumber from "../common/PhoneNumber"
import Switch from "../common/Switch"
import DeletePrompt from "../common/DeletePrompt"
import EditUserProfile from "./EditUserProfile"
import moment from "moment"
import "./userdetails.css"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    getCandidateInterviewsAction,
    getUserDetailAction,
    getUserLogsAction, postMediaToAWS, updateUserProfileAction,
    userChangeActiveStatusAction
} from "../../../redux/actions/AdminActions"
import {
    getSingleUserDetailSelector,
    getSingleUserInterviewDetailSelector,
    getSingleUserProfileDetailSelector
} from "../../../redux/selectors/AdminSelector"
import userEvent from "@testing-library/user-event";
import {ToastContainer, toast} from "react-toastify";

const UserDetail = (props) => {
    const {
        getUserDetail,
        getCurrentUser,
        getCurrentUserInterview,
        getCurrentUserProfile,
        getUserInterviews,
        getUserLogs,
        userChangeActiveStatue,
        updateUserProfile,
        postMediaToAWSAction
    } = props;
    const [userId, setUserId] = useState(String(props?.location?.pathname).split("/user-detail/")[1])
    const [editProfile, setEditProfile] = useState(false);
    const [modal, setModal] = useState(false);
    const [statsModal, setStatsModal] = useState(undefined);
    const [status, setStatus] = useState(false)
    const [file, setFile] = useState(undefined)
    const hiddenFileInput = useRef(null);

    const renderRating = (rating) => {
        if (rating === 0 || !rating) return "secondary"
        if (rating === 1 || rating === 2) return "danger"
        if (rating === 3) return "warning"
        if (rating === 4 || rating === 5) return "success"
    }

    useEffect(() => {

        setStatus(getCurrentUser?.is_active);
        if (getCurrentUser) {
            const data = {
                // "filter_options": {
                //     "name": "",
                //     "position": "",
                //     "status": "",
                //     "tag": "",
                //     "response": "",
                //     "rating": "",
                //     "team_member": "",
                //     "complete_date": "",
                //     "added_date": "",
                //     "sort_array": []
                // },
                pagination: {
                    "page": 1,
                    "size": 10
                },
                candidate_id: getCurrentUser.id
            }
            getUserInterviews(data)
            // const d = {
            //     "filter_options": {
            //         "user_name": "ec",
            //         "model": "email@email.com",
            //         "url": "",
            //         "created_at": "",
            //         "sort_array": [["member_name", "DESC"], ["createdAt", "ASC"]]
            //     },
            //     "pagination": {
            //         "page": 1,
            //         "size": 3
            //     },
            //     user_id: getCurrentUser.id
            // }
            // getUserLogs(d)
        }

    }, [getCurrentUser])

    useEffect(() => {
        const id = String(props?.location?.pathname).split("/user-detail/")[1];
        setUserId(id);
        if (id) {
            getUserDetail({user_id: id});
        } else {
            props.history.push("/users");
        }

    }, [props?.location?.pathname])
    const toastId = useRef(null);
    const handleChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const media_key =
                "user/" + file.lastModified + "." + file.type.split("/")[1];
            postMediaToAWSAction({key: media_key, file: file, user_id: userId}, (res) => {
                if (res.config.url) {
                    toast.dismiss(toastId.current);
                }
            });
            toastId.current = toast.success("Uploading ...", {autoClose: false});
        }
    };

    return (
        <>
            <Row>
                <Col lg={4}>
                    <Card
                        className="profile-data h-unset"
                        style={{
                            borderTop: "4px solid #001c35",
                        }}
                    >
                        <div class="card-header">
                            <h5 class="card-title text-center">{getCurrentUser?.first_name} {getCurrentUser?.last_name}</h5>
                        </div>

                        <CardBody>
                            <div>
                                <img src={getCurrentUser?.image ? getCurrentUser?.image : avatar} alt="user image"
                                     className="img-thumbnail rounded-circle profile-image"/>
                            </div>
                            <div className="mt-2 d-flex flex-column justify-content-end">
                                <div>
                  <span className="d-none mb-1">
                    <i className="bx bx-mail-send"></i>
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bx-phone-call"></i> &nbsp;
                                        {/*<PhoneNumber number={'102548481'} /> */}
                                        {getCurrentUser?.phone}
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bxs-envelope"></i> &nbsp;
                                        {getCurrentUser?.email}
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bx-calendar-alt"></i> &nbsp;
                                        {moment(getCurrentUser?.createdAt).format("MMM DD, YYYY")}
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bxs-calendar-alt"></i> &nbsp;
                                        {moment(getCurrentUser?.updatedAt).format("MMM DD, YYYY")}
                  </span>
                                </div>
                                <button className="btn-block btn btn-outline-primary mt-3 "
                                        onClick={() => setEditProfile(true)}>
                                    Edit Profile
                                </button>
                            </div>
                            {/* </Row> */}
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <div
                        className="card"
                        style={{
                            borderTop: "4px solid #00ba52",
                        }}
                    >
                        <div className="card-header">
                            <h5 className="card-title">Interview</h5>
                        </div>
                        <div className="card-body">
                            <table className="table table-sm table-hover table-borderless table-striped">
                                <tbody>
                                <tr>
                                    <td>Account Active</td>
                                    <td>
                                        <div className="d-flex">
                                            <Switch
                                                isOn={status}
                                                forId={`user-edit-status-item-${"user id here"}`}
                                                handleToggle={() => {
                                                    userChangeActiveStatue({
                                                        user_id: getCurrentUser?.id,
                                                        status: !status
                                                    })
                                                    setStatus(!status)
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pending</td>
                                    <td>{getCurrentUserInterview?.interview_pending}</td>
                                </tr>
                                <tr>
                                    <td>Started</td>
                                    <td>{getCurrentUserInterview?.interview_started}</td>
                                </tr>
                                <tr>
                                    <td>Completed</td>
                                    <td>{getCurrentUserInterview?.interview_completed}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="text-dark d-none">
                                <div className="mb-1">
                                    <div>
                                        <span>Pending</span>
                                        <span className="float-right">5</span>
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <div>
                                        <span>Started</span>
                                        <span className="float-right">3</span>
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <div>
                                        <span>Completed</span>
                                        <span className="float-right">5</span>
                                    </div>
                                </div>
                            </div>

                            <Col lg={12} className="mt-3 p-0">
                                <div className="d-md-flex justify-content-between">
                                    <div className="col-5 p-0 align-self-center">
                                        <ResumeIcon/>
                                    </div>
                                    <div className="col">
                                        <h5 className="mb-1 title-color">PDF Resume </h5>


                                        <div className="">
                                            {getCurrentUserProfile?.pdf_resume ?
                                                <>
                                                    <a
                                                        href={getCurrentUserProfile?.pdf_resume}
                                                        target="_blank"
                                                        type="button"
                                                        className="btn btn-outline-dark btn-sm mini mb-md-3"
                                                        name="PDF Resume-resume"
                                                    >
                                                        Preview
                                                    </a>
                                                    {/*<button*/}
                                                    {/*    type="button"*/}
                                                    {/*    className="btn btn-link text-danger btn-sm mini d-flex align-items-center mb-3"*/}
                                                    {/*    name="PDF Resume-resume"*/}
                                                    {/*    onClick={() => setModal({*/}
                                                    {/*        show: true,*/}
                                                    {/*        fn: () => {*/}
                                                    {/*            console.log('Remove Resume');*/}
                                                    {/*            setModal(undefined)*/}
                                                    {/*        },*/}
                                                    {/*        title: 'remove pdf resume',*/}
                                                    {/*        btn: 'Remove'*/}
                                                    {/*    })}*/}
                                                    {/*>*/}
                                                    {/*    Remove <span className="body-title ml-1">×</span>*/}
                                                    {/*</button>*/}
                                                </>
                                                :
                                                <>
                                                    <p className="mb-1 title-color">Upload Resume </p>
                                                    {/*<p className="extra-small-font mb-1">Upload your Resume in PDF*/}
                                                    {/*    format</p>*/}
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            accept="application/pdf"
                                                            ref={hiddenFileInput}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-outline-secondary btn-md"
                                                            onClick={() => {
                                                                // handleSubmit();
                                                                hiddenFileInput.current.click()
                                                            }}
                                                        >
                                                            Upload Resume
                                                        </button>

                                                    </div>
                                                </>
                                            }


                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Col>
                <Col lg={4}>
                    <div
                        className="card h-unset"
                        style={{
                            borderTop: "4px solid #5743d9",
                        }}
                    >
                        <div className="card-header">
                            <h5 className="card-title">Video Resume</h5>
                        </div>
                        <div className="card-body d-flex">
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="mb-1 body-title dark-color"></p>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <button*/}
                                    {/*        type="button"*/}
                                    {/*        className="btn btn-link text-danger btn-sm mini d-flex align-items-center mb-1"*/}
                                    {/*        name="Video Resume-resume"*/}
                                    {/*        onClick={() => setModal({*/}
                                    {/*            show: true,*/}
                                    {/*            fn: () => {*/}
                                    {/*                console.log('Remove Video');*/}
                                    {/*                setModal(undefined)*/}
                                    {/*            },*/}
                                    {/*            title: 'remove video resume',*/}
                                    {/*            btn: 'Remove'*/}
                                    {/*        })}*/}
                                    {/*    >*/}
                                    {/*        Remove <span className="body-title ml-1 ">×</span>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                                {
                                    getCurrentUserProfile?.video_resume ?
                                        <video controls width="100%" height="88%">
                                            <source src={getCurrentUserProfile?.video_resume} type="video/webm"/>
                                            Your browser does not support the video tag.
                                        </video> :
                                        ""

                                }

                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Interviews setModal={setModal} getCurrentUser={getCurrentUser}/>

            <Logs statsModal={statsModal} setStatsModal={setStatsModal} userId={userId}/>
            <Modal
                centered={true}
                show={editProfile}
                onHide={() => {
                    setEditProfile(false)
                }}
            >
                <EditUserProfile user={getCurrentUser} updateUserProfileAction={updateUserProfile}
                                 getUserDetail={getUserDetail} toggle={() => {
                    setEditProfile(false)
                }}/>
            </Modal>

            <Modal
                centered={true}
                show={modal?.show}
                onHide={() => {
                    setModal(undefined);
                }}
            >
                <DeletePrompt
                    title={`Are you sure you want to ${modal?.title}?`}
                    btnText={modal?.btn}
                    func={() => modal?.fn()}
                    toggle={() => {
                        setModal(undefined);
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                size="lg"
                show={statsModal}
                onHide={() => {
                    setStatsModal(false)
                }}
            >
                <Card>
                    <CardBody>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>URL</th>
                                <th>PARAMETERS</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>/dashboard</td>
                                <td>
                                    <JSONPretty
                                        json={{
                                            company: "b63549e7-3771-4a90-98b9-6047185a4c86",
                                            pagination: {page: 1, size: 10},
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>STATE</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <JSONPretty
                                        json={{
                                            name: "Sadiq Public School",
                                            industry: "Education Management",
                                            logo: "blob:https://employers.digitalhire.net/35a5d67d-34b3-4f4d-9c4e-d83e7c22156f",
                                            email: "robas@yahoo.com",
                                            website: "www.youtube.com",
                                            address: "Address 1",
                                            address2: "Address 2",
                                            city: "Islamabad",
                                            state: "Islamabad",
                                            country: "PK",
                                            postal_code: "46000",
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Users: getUsersSelector(state),
        getCurrentUser: getSingleUserDetailSelector(state),
        getCurrentUserInterview: getSingleUserInterviewDetailSelector(state),
        getCurrentUserProfile: getSingleUserProfileDetailSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDetail: (data) => dispatch(getUserDetailAction(data)),
        getUserInterviews: (data) => dispatch(getCandidateInterviewsAction(data)),
        getUserLogs: (data) => dispatch(getUserLogsAction(data)),
        updateUserProfile: (data, cb) => dispatch(updateUserProfileAction(data, cb)),
        userChangeActiveStatue: (data, cb) => dispatch(userChangeActiveStatusAction(data, cb)),
        postMediaToAWSAction: (data, callback) =>
            dispatch(postMediaToAWS(data, callback)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetail))
