import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
        <div className="copyright text-right">
          {/* <p>
            Copyright © Designed &amp; Developed by{" "}
            <a href="http://digitalhire.com/" target="_blank" rel="noreferrer">
              Digitalhire
            </a>{" "}
            {d.getFullYear()}

          </p> */}
            <div className="text-secondary mx-2 terms_condition">
              <a rel="noreferrer" href="https://digitalhire.com/privacy-policy" target="_blank" className="text-secondary mx-1 small">Privacy Policy</a>|
              <a rel="noreferrer" href="https://digitalhire.com/terms-of-service" className="text-secondary mx-1 small" target="_blank">Terms &amp; Conditions</a>|
              <a rel="noreferrer" href="https://digitalhire.com/faqs" className="text-secondary mx-1 small" target="_blank">Help Center</a>
            </div>
        </div>
    </div>
  );
};

export default Footer;
