import axiosInstance from "./AxiosInstance"
import { 
  logOutAuth,
  loginSuccessAction
} from "./../redux/actions/AuthActions";

export const loginService = (dataForm) => {
  const postData = {
    email: dataForm.email,
    password: dataForm.password,
    //type: "admin", //Missing in backend API
  }
  return axiosInstance.post(`/accounts/login`, postData)
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  )
  localStorage.setItem("adminData", JSON.stringify(tokenDetails))
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logOutAuth(history))
  }, timer)
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("adminData")
  let tokenDetails = ""
  if (!tokenDetailsString) {
    dispatch(logOutAuth(history))
    return
  }

  tokenDetails = JSON.parse(tokenDetailsString)
  let expireDate = new Date(tokenDetails.expireDate)
  let todaysDate = new Date()

  if (todaysDate > expireDate) {
    dispatch(logOutAuth(history))
    return
  }
  if(tokenDetails.company_profile===true && tokenDetails.verified===true )
  {
    dispatch(loginSuccessAction(tokenDetails))
  }
  const timer = expireDate.getTime() - todaysDate.getTime()
  runLogoutTimer(dispatch, timer, history)
}
