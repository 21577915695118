import React, {useState, useRef, useCallback} from "react";
import {Link} from "react-router-dom";
import {
    Row,
    Col,
} from "reactstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch, useSelector} from "react-redux";
import "./company.css";
import moment from "moment"

const CompanyFilter = ({query, setQuery}) => {
    const [companyDate, setCompanyDate] = useState(null);
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const {Companies, CompanyDataLoading, Plans} = useSelector(
        (state) => state.Admin
    );

    const clearFilters = () => {
        setCompanyDate(null);
        setQuery({});
        formRef.current.reset();
    };

    const remove = useCallback((key) => {
        setQuery(({[key]: value, ...query}) => query);
    }, []);

    const handleDate = (date) => {
        if (date) {
            setCompanyDate(date);
            setQuery({
                ...query,
                createdAt: date,
            });
        } else {
            remove('createdAt')
            setCompanyDate(date);
        }
    };

    const nameChange = (value) => {
        if (value.keyCode === 13)
            setQuery({
                ...query,
                name: value.target.value,
            });
    };

    const phoneChange = (value) => {
        if (value.keyCode === 13)
            setQuery({
                ...query,
                phone: value.target.value,
            });
    };

    const emailChange = (value) => {
        if (value.keyCode === 13)
            setQuery({
                ...query,
                email: value.target.value,
            });
    };

    return (
        <div className="card candidate-filters  border border-primary">
            <div className="card-header">
                <h5 className="card-title">Filters</h5>
            </div>
            <form ref={formRef} className=" card-body pb-0 ">
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col lg={3}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <input
                                                name="name"
                                                placeholder="Name"
                                                className="form-control"
                                                onKeyUp={(e) => nameChange(e)}
                                                type="text"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <input
                                                name="email"
                                                placeholder="Email-Address"
                                                className="form-control"
                                                onKeyUp={(e) => emailChange(e)}
                                                type="text"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={3}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <input
                                                name="phone"
                                                placeholder="Phone Number"
                                                className="form-control"
                                                onKeyUp={(e) => phoneChange(e)}
                                                type="text"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <select
                                                name="subscription_name"
                                                className="form-control"
                                                type="select"
                                                onChange={(e) =>
                                                    setQuery({
                                                        ...query,
                                                        subscription_name: e.target.value,
                                                    })
                                                }
                                                value={query.subscription_name}
                                            >
                                                <option value="">Subscription</option>
                                                {Plans.Subscriptions &&
                                                    Plans?.Subscriptions?.map(({name}) => (
                                                        <option value={name} key={`sub-optioj${name}`}>
                                                            {name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={3}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <select
                                                name="subscription_name"
                                                className="form-control"
                                                type="select"
                                                onChange={(e) =>
                                                    setQuery({
                                                        ...query,
                                                        company_status: e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="">Company Status</option>
                                                <option value={1}>Active</option>
                                                )
                                                <option value={0}>Inactive</option>)
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="docs-datepicker form-group">
                                            {/* <label className="text-label">Date Completed</label> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    clearable
                                                    format="yyyy-MM-dd"
                                                    className="form-control"
                                                    placeholder="Created Date"
                                                    value={companyDate}
                                                    onChange={(e) => handleDate(e)
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col></Col>
                            <Col lg={2}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btn-block"
                                                onClick={() => clearFilters()}
                                            >
                                                Clear Filters
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default CompanyFilter;
