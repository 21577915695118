export const getAuthLoadingSelector = (state) => {
  if (state?.Auth?.AuthDataLoading) return state.Auth.AuthDataLoading
  return false
}
export const isAuthenticated = (state) => {
  if (state?.Auth?.AuthData?.token) return true
  return false
}
export const isVerified = (state) => {
  if (state?.Auth?.AuthData.verified) return true
  return false
}
