import React, {useEffect, useState} from "react"
import {
    Card,
    CardBody,
    Table,
    Button,
} from "reactstrap"
import TeamMemberFilter from "./TeamMemberFilter";
import Pagination from "../common/Pagination/Pagination";
import pic1 from "../../../images/default.svg"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    deleteCompanyMemberAction,
    getCompanyProfileAction,
    getCompanyTeamMembersAction, getRoles, getUsers
} from "../../../redux/actions/AdminActions";
import {getCompanyProfileSelector, getCompanyTeamMembersSelector} from "../../../redux/selectors/AdminSelector";

const TeamMember = ({
                        setInviteModal,
                        setModalShow,
                        companyId,
                        loadCompanyTeamMembersData,
                        companyTeamMembersData,
                        deleteCompanyTeamMember,
                        getRolesAction
                    }) => {
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "ASC"]);
    const [trackSort, setTrackSort] = useState(0);

    useEffect(() => {
        if (companyId) getTeamMemberData();
    }, [companyId, query, sort])

    useEffect(() => {
        getRolesAction()
    }, []);


    const getTeamMemberData = (page = 1) => {
        const data = {
            "company_id": companyId,
            "filter_options": {
                ...query,
                "sort_array": [sort]
            },
            "pagination": {
                "page": page,
                "size": 10
            }
        }
        loadCompanyTeamMembersData(data)
    }

    return (
        <>
            <TeamMemberFilter query={query} setQuery={setQuery}/>
            <Card className="mb-2">
                <CardBody>
                    <div>
                        <div>
                            <h4>Team Members</h4>
                        </div>
                        <div className="float-right" style={{marginTop: "-2.3rem"}}>
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    setInviteModal(true)
                                }}
                            >
                                Add Members
                            </Button>
                        </div>
                        <hr/>
                    </div>
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "member_name" && prev[1] === "ASC"
                                            ? ["member_name", "DESC"]
                                            : ["member_name", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                Name
                                <span className="ml-1">
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "member_email" && prev[1] === "ASC"
                                            ? ["member_email", "DESC"]
                                            : ["member_email", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                Email
                                <span className="ml-1">
                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "member_phone" && prev[1] === "ASC"
                                            ? ["member_phone", "DESC"]
                                            : ["member_phone", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                Phone
                                <span className="ml-1">
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "member_role" && prev[1] === "ASC"
                                            ? ["member_role", "DESC"]
                                            : ["member_role", "ASC"];
                                    });
                                    setTrackSort(5);
                                }}
                            >
                                Role
                                <span className="ml-1">
                                    {trackSort === 5 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            {/*<th*/}
                            {/*    style={{overflow: "hidden", cursor: "pointer"}}*/}
                            {/*    onClick={() => {*/}
                            {/*        setSort((prev) => {*/}
                            {/*            return prev[0] === "member_position" && prev[1] === "ASC"*/}
                            {/*                ? ["member_position", "DESC"]*/}
                            {/*                : ["member_position", "ASC"];*/}
                            {/*        });*/}
                            {/*        setTrackSort(6);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Positions*/}
                            {/*    <span className="ml-1">*/}
                            {/*        {trackSort === 6 ? (*/}
                            {/*            sort[1] === "DESC" ? (*/}
                            {/*                <i className="fa fa-arrow-down"/>*/}
                            {/*            ) : (*/}
                            {/*                <i className="fa fa-arrow-up"/>*/}
                            {/*            )*/}
                            {/*        ) : (*/}
                            {/*            ""*/}
                            {/*        )}*/}
                            {/*    </span>*/}
                            {/*</th>*/}
                            {/*<th*/}
                            {/*    style={{overflow: "hidden", cursor: "pointer"}}*/}
                            {/*    onClick={() => {*/}
                            {/*        setSort((prev) => {*/}
                            {/*            return prev[0] === "member_evaluation" && prev[1] === "ASC"*/}
                            {/*                ? ["member_evaluation", "DESC"]*/}
                            {/*                : ["member_evaluation", "ASC"];*/}
                            {/*        });*/}
                            {/*        setTrackSort(7);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Evaluations*/}
                            {/*    <span className="ml-1">*/}
                            {/*        {trackSort === 7 ? (*/}
                            {/*            sort[1] === "DESC" ? (*/}
                            {/*                <i className="fa fa-arrow-down"/>*/}
                            {/*            ) : (*/}
                            {/*                <i className="fa fa-arrow-up"/>*/}
                            {/*            )*/}
                            {/*        ) : (*/}
                            {/*            ""*/}
                            {/*        )}*/}
                            {/*    </span>*/}
                            {/*</th>*/}
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {companyTeamMembersData?.CompanyMembers?.rows?.map(item => {
                                console.log(item);
                                return (
                                    <tr key={`company-detail-team-member-${item.id}`}>
                                        <td>
                                            <img className="rounded-circle" width="35"
                                                 src={item?.AddedMemberUser?.image || pic1} alt=""/>
                                            &nbsp;&nbsp;{item?.AddedMemberUser?.first_name} {item?.AddedMemberUser?.last_name}
                                        </td>
                                        <td>{item?.AddedMemberUser?.email}</td>
                                        <td>{item?.AddedMemberUser?.phone}</td>
                                        <td>{item?.AddedMemberUser?.Roles?.[0]?.name}</td>
                                        {/*<td>25</td>*/}
                                        {/*<td>5</td>*/}
                                        <td>
                                            <i
                                                className="bx bx-trash"
                                                style={{fontSize: "2rem", cursor: "pointer"}}
                                                onClick={() => {
                                                    setModalShow({
                                                        show: true,
                                                        msg: 'member',
                                                        fn: () => {
                                                            const d = {
                                                                company_id: companyId,
                                                                company_member_id: item.id,
                                                                mem_id: item?.AddedMemberUser?.id
                                                            }

                                                            deleteCompanyTeamMember(d, () => {
                                                                const data = {
                                                                    "company_id": companyId,
                                                                    "pagination": {
                                                                        "page": 1,
                                                                        "size": 10
                                                                    }
                                                                }
                                                                loadCompanyTeamMembersData(data)
                                                            })
                                                            console.log('member');
                                                            setModalShow(undefined);
                                                        }
                                                    })
                                                }}
                                            ></i>
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-center mb-4">
                <Pagination
                    pages={1}
                    page={1}
                    setPage={(page) => getTeamMemberData(page)}
                    // setPage={(page) => console.log(page)}
                    count={3}
                />
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyTeamMembersData: (data) => dispatch(getCompanyTeamMembersAction(data)),
        deleteCompanyTeamMember: (data, cb) => dispatch(deleteCompanyMemberAction(data, cb)),
        getRolesAction: () => dispatch(getRoles()),
    }
}

const mapStateToProp = (state) => {
    return {
        companyTeamMembersData: getCompanyTeamMembersSelector(state),
    }
}
export default withRouter(connect(mapStateToProp, mapDispatchToProps)(TeamMember))