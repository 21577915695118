import React, {useState, useEffect, useRef} from "react";
import {Link, withRouter} from "react-router-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // ModalFooter,
    Input,
    Form,
    FormGroup,
    Label,
    Table,
    Spinner,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Badge
} from "reactstrap";
import {Modal} from "react-bootstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Pagination from "../common/Pagination/Pagination";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getCompanies,
    getCompanyProfileAction,
    getPlans,
    updateCompanyStatusAction
} from "../../../redux/actions/AdminActions";
import pic1 from "../../../images/default.svg";
import CompanyFilter from "./CompanyFilter";
import DeletePrompt from "../common/DeletePrompt";
import "./company.css";
import {getCompanyProfileSelector} from "../../../redux/selectors/AdminSelector";

const Company = (props) => {
    const dispatch = useDispatch();
    const {Companies, CompanyDataLoading, Plans} = useSelector(
        (state) => state.Admin
    );
    const {updateCompanyStatus} = props
    const [modalShow, setModalShow] = useState(false);
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [trackSort, setTrackSort] = useState(0);
    const [resetPass, setResetPass] = useState(null);

    useEffect(() => {
        dispatch(getPlans());
    }, []);

    useEffect(() => {
        onSubmit();
    }, [sort, query]);


    const onSubmit = (page = 1) => {
        const data = {
            filter_options: {
                ...query,
                sort_array: [sort],
            },
            pagination: {
                page: page,
                size: 10,
            },
        };
        dispatch(getCompanies(data));
    };

    console.log('index', Companies?.Companies?.data);

    return (
        <div className="mb-4">
            <CompanyFilter query={query} setQuery={setQuery}/>

            {/*<div className="d-flex justify-content-end mr-1">*/}
            {/*    <div className="form-group col-2">*/}
            {/*        <button*/}
            {/*            className="btn btn-primary btn-block"*/}
            {/*            onClick={() => setModalShow(true)}*/}
            {/*        >*/}
            {/*            Export*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="card">
                <div className="card-body">
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th></th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "name" && prev[1] === "ASC"
                                            ? ["name", "DESC"]
                                            : ["name", "ASC"];
                                    });
                                    setTrackSort(1);
                                }}
                            >
                                Name
                                <span className="ml-1">
                    {trackSort === 1 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "name" && prev[1] === "ASC"
                                            ? ["email", "DESC"]
                                            : ["email", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                Email
                                <span className="ml-1">
                    {trackSort === 2 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "phone" && prev[1] === "ASC"
                                            ? ["phone", "DESC"]
                                            : ["phone", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                Phone
                                <span className="ml-1">
                    {trackSort === 3 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "subscription_name" &&
                                        prev[1] === "ASC"
                                            ? ["subscription_name", "DESC"]
                                            : ["subscription_name", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                Subscription
                                <span className="ml-1">
                    {trackSort === 4 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "expiryDate" && prev[1] === "ASC"
                                            ? ["createdAt", "DESC"]
                                            : ["createdAt", "ASC"];
                                    });
                                    setTrackSort(5);
                                }}
                            >
                                Created Date
                                <span className="ml-1">
                    {trackSort === 5 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "isActive" && prev[1] === "ASC"
                                            ? ["status", "DESC"]
                                            : ["status", "ASC"];
                                    });
                                    setTrackSort(6);
                                }}
                            >
                                Active
                                <span className="ml-1">
                    {trackSort === 6 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        {CompanyDataLoading ? (
                            <tbody>
                            <tr>
                                <td style={{textAlignLast: "center"}} colSpan={9}>
                                    <Spinner
                                        size="sm"
                                        className="m-auto text-center"
                                        color="success"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        ) : Companies?.Companies?.data.length ? (
                            Companies?.Companies?.data?.map((item, index) => {
                                return (
                                    <tbody key={`company-id${item.id}`}>
                                    <tr>
                                        <td style={{width: "37px"}}>
                                            <img
                                                className="rounded-circle"
                                                width="35"
                                                src={item?.logo || pic1}
                                                alt=""
                                            />
                                        </td>
                                        <td>
                                            <Link
                                                style={{pointer: "cursor"}}
                                                to={`/company-detail/${item.id}`}
                                            >
                                                {item.name}
                                            </Link>
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item?.user?.phone}</td>
                                        <td>{item?.Subscription?.name ? item?.Subscription?.name : "None"}</td>
                                        <td>
                                            {item.createdAt.slice(0, item.createdAt.indexOf("T"))}
                                        </td>
                                        <td>{item?.status ?
                                            <Badge color={"light"} className="font-size-12">True</Badge> :
                                            <Badge color={"danger"} className="font-size-12">False</Badge>}</td>
                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    href="#"
                                                    className="card-drop"
                                                    tag="i"
                                                >
                                                    <i className="mdi mdi-dots-horizontal font-size-18"/>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {/*<DropdownItem*/}
                                                    {/*    className="text-primary"*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        setResetPass({*/}
                                                    {/*            show: true,*/}
                                                    {/*            func: () => {*/}
                                                    {/*                console.log("erer");*/}
                                                    {/*                // resetPass({interview_id: item?.id}, props, () => {*/}
                                                    {/*                //     showAlert()*/}
                                                    {/*                // })*/}
                                                    {/*                // setProgressModal(null)*/}
                                                    {/*            },*/}
                                                    {/*        });*/}
                                                    {/*    }}*/}
                                                    {/*>*/}
                                                    {/*    Password Reset*/}
                                                    {/*</DropdownItem>*/}
                                                    <DropdownItem className="text-dark" onClick={() => {
                                                        const data = {
                                                            "status": false,
                                                            "company_id": item?.id
                                                        }
                                                        updateCompanyStatus(data, () => {
                                                            onSubmit()
                                                        })


                                                    }}>
                                                        Account Deactivate
                                                    </DropdownItem>
                                                    {/*<DropdownItem className="text-dark">*/}
                                                    {/*    {" "}*/}
                                                    {/*    Plan Change*/}
                                                    {/*</DropdownItem>*/}

                                                    {/*<DropdownItem className="text-danger">*/}
                                                    {/*    Duplicate Accounts*/}
                                                    {/*</DropdownItem>*/}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    </tbody>
                                );
                            })
                        ) : (
                            <tbody>
                            <tr>
                                <td style={{textAlignLast: "center"}} colSpan={9}>
                                    No Data To Display
                                </td>
                            </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                {!CompanyDataLoading && (
                    <Pagination
                        pages={Companies?.Companies?.totalPages}
                        page={Companies?.Companies?.currentPage}
                        setPage={(page) => onSubmit(page)}
                        count={Companies?.Companies?.totalItems}
                    />
                )}
            </div>
            <Modal
                centered={true}
                show={resetPass?.show}
                onHide={() => {
                    setResetPass(null);
                }}
            >
                <DeletePrompt
                    title={"Are you sure you want to reset this copany password?"}
                    btnText={"Reset"}
                    func={() => resetPass?.func()}
                    toggle={() => {
                        setResetPass(null);
                    }}
                />
            </Modal>
            <Modal
                // size="lg"
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <div className="p-3">
                    <Row>
                        <Col lg={12} className="text-center mt-2">
                            <h4>{"Select Your Desire Columns"}</h4>
                            {/* <h6 className="mt-3">{message}</h6> */}
                        </Col>
                    </Row>
                    <Modal.Body>
                        <Form>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Names
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Address
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Phone
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> SUBSCRIPTION
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> EXPIRY DATE
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> STATUS
                                </Label>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Row className="justify-content-center mb-2">
                        {/* <Col className="text-center" lg={8}> */}
                        <button
                            type="button"
                            onClick={() => {
                                setModalShow(false);
                            }}
                            style={{paddingInline: "25px"}}
                            className="btn btn-outline-secondary mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setModalShow(false);
                            }}
                            style={{paddingInline: "30px"}}
                            className={`btn btn-primary ml-2`}
                        >
                            Export
                        </button>
                        {/* </Col> */}
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyProfileData: (data) => dispatch(getCompanyProfileAction(data)),
        updateCompanyStatus: (data, cb) => dispatch(updateCompanyStatusAction(data, cb)),
    }
}

const mapStateToProp = (state) => {
    return {
        companyProfileData: getCompanyProfileSelector(state)
    }
}

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Company))

