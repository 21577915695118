import React, {useState, useEffect, useRef} from "react"

import {Modal, Row, Col, Button, OverlayTrigger, Tooltip} from "react-bootstrap"
import {
    Card,
    CardBody,
    Table,
    Progress,
    Badge,
    Spinner
} from "reactstrap"
import Pagination from "../common/Pagination/Pagination";
import avatar from "../../../images/default.svg"
import moment from "moment"
import "./userdetails.css"
import InterviewFilter from "./InterviewFilter";
import {
    getSingleUserDetailSelector,
    getSingleUserInterviewDetailSelector, getSingleUserInterviewsSelector, getSingleUserProfileDetailSelector
} from "../../../redux/selectors/AdminSelector";
import {
    addCandidateInterviewAction, deleteCandidateInterviewAction,
    getCandidateInterviewsAction,
    getUserDetailAction
} from "../../../redux/actions/AdminActions";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

const Interviews = (props) => {
    const {
        setModal,
        addNewInterview,
        getSingleUserInterviews,
        getUserInterviews,
        getCurrentUser,
        deleteInterview,
        removeCompanyCandidateTag,
        showAlert,
        subData,
    } = props
    const [query, setQuery] = useState({})
    const [sort, setSort] = useState(["createdAt", "ASC"]);
    const [trackSort, setTrackSort] = useState(0);
    const [interviewUrl, setInterviewUrl] = useState("");
    const [tagModal, setTagModal] = useState(null);
    const firstRender = useRef(true);
    const [teamMemberModal, setTeamMemberModal] = useState(null);
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [interviewLimitModel, setInterviewLimitModel] = useState({show: false, func: undefined});


    useEffect(() => {
        console.log(firstRender.current);
        if (!firstRender.current) {
            onSubmit()
        } else {
            firstRender.current = false;
        }
    }, [query, sort])

    const onSubmit = (page = 1) => {
        const data = {
            candidate_id: getCurrentUser.id,
            "filter_options": {
                ...query,
                "sort_array": [sort]
            },
            "pagination": {
                "page": page,
                "size": 10
            }
        }
        setLoader(true);
        getUserInterviews(data, () => setLoader(false));
    }

    const renderRating = (rating) => {
        if (rating === 0 || !rating) return "secondary"
        if (rating === 1 || rating === 2) return "danger"
        if (rating === 3) return "warning"
        if (rating === 4 || rating === 5) return "success"
    }

    const handleAddInterview = () => {
        if (interviewUrl !== "" && interviewUrl !== " ") {
            const data = {
                position_url: interviewUrl.substr(interviewUrl.lastIndexOf("/") + 1),
                candidate_id: getCurrentUser.id
            }
            console.log({data});
            setBtnLoader(true);
            addNewInterview(data, (bol) => {
                const data = {
                    filter_options: {
                        //     ...query,
                        "sort_array": [sort]
                    },
                    pagination: {
                        "page": 1,
                        "size": 10
                    },
                    candidate_id: getCurrentUser.id
                }
                if (bol) getUserInterviews(data);
                setBtnLoader(false);
            });
        }
    };

    // console.log("canData", canData)
    const [progressModal, setProgressModal] = useState({
        show: false,
        func: null,
    })

    const [deleteModal, setDeleteModal] = useState({
        show: false,
        func: null,
    })
    // const renderRating = (myResponse) => {
    //     if (myResponse === "maybe" || myResponse === "pending" || !myResponse) return "light"
    //     if (myResponse === "no") return "danger"
    //     if (myResponse === "yes") return "primary"
    // }

    const toggleTag = (tag, interviewID, cb) => {
        // console.log(tag);
        removeCompanyCandidateTag(
            {
                tag_id: tag,
                interview_id: interviewID,
            },
            props,
            (bol) => {
                cb && cb(bol)
                bol && showAlert()
            }
        )
    }
    console.log("Interviews: ", getSingleUserInterviews)
    const renderStatus = (status) => {
        if (status === 'completed') return 'Completed'
        if (status === 'reviewed') return 'Evaluated'
        if (status === 'startedInterview' || status === 'started_interview') return 'Started Interview'
        if (status === 'invited') return 'Invited'
        if (status === 'clickedInvite' || status === 'clicked_invite') return 'Clicked Invite'
        if (status === 'hired') return 'Hired'
        return status
    }
    const renderResponse = (resp) => {
        if (resp === 'yes') return 'Yes'
        if (resp === 'no') return 'No'
        if (resp === 'maybe') return 'Maybe'
        return resp
    }
    const renderTooltip = (props, text) => {
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }

    const renderInterviewTags = (tags, interviewID, cb) => {
        let result = []
        if (tags && tags.length > 0) {
            result = []
            tags.map((tag, i) => {
                result.push(
                    <Badge
                        key={`${tag?.id}-${i}`}
                        style={{color: tag?.color, backgroundColor: `${tag?.color}20`}}
                        className="mr-1"
                    >
                        {tag?.name}
                        <span
                            onClick={() => toggleTag(tag?.Interviews_Tag?.tag_id, interviewID, (cb = null))}
                            className="badge font-size-10 ml-1"
                            style={{cursor: "pointer"}}
                        >
              X
            </span>
                    </Badge>
                )
            })
        }
        return result
    }
    return (
        <>
            <Card>
                <CardBody>
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-1">Add interview link provided to you by the employer</p>
                        </div>
                        <div className="col-9">
                            <input
                                type="text"
                                className="form-control"
                                id="val-fname1"
                                placeholder="Enter Url"
                                onChange={(e) => setInterviewUrl(e.target.value)}
                                value={interviewUrl}
                            />
                        </div>
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn-block"
                                style={{height: "41px"}}
                                disabled={btnLoader}
                                onClick={handleAddInterview}
                            >
                                {btnLoader ? <Spinner size="sm" className="m-auto text-center" color="#001c35"/>
                                    : 'Add Interview'
                                }
                            </button>
                        </div>
                        {/* <div className="text-danger ml-3" ref={interviewError}></div> */}
                    </div>
                </CardBody>
            </Card>
            <InterviewFilter query={query} setQuery={setQuery}/>
            <Card className="mb-2">
                <div className="card-header">
                    <h5 className="card-title">Interviews</h5>
                </div>
                <CardBody>
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    // setSort((prev) => {
                                    //     return prev[0] === "name" && prev[1] === "ASC"
                                    //         ? ["name", "DESC"]
                                    //         : ["name", "ASC"];
                                    // });
                                    // setTrackSort(1);
                                }}
                            >
                                Name
                                <span className="ml-1">
                    {trackSort === 1 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>

                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "status" && prev[1] === "ASC"
                                            ? ["status", "DESC"]
                                            : ["status", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                status
                                <span className="ml-1">
                    {trackSort === 2 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>

                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "start_date" && prev[1] === "ASC"
                                            ? ["start_date", "DESC"]
                                            : ["start_date", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                Date Added
                                <span className="ml-1">
                    {trackSort === 3 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>

                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "progress" && prev[1] === "ASC"
                                            ? ["progress", "DESC"]
                                            : ["progress", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                Int. Progress
                                <span className="ml-1">
                    {trackSort === 4 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>

                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "completion_date" && prev[1] === "ASC"
                                            ? ["completion_date", "DESC"]
                                            : ["completion_date", "ASC"];
                                    });
                                    setTrackSort(5);
                                }}
                            >
                                Completion Date
                                <span className="ml-1">
                    {trackSort === 5 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>

                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "ratings" && prev[1] === "ASC"
                                            ? ["ratings", "DESC"]
                                            : ["ratings", "ASC"];
                                    });
                                    setTrackSort(6);
                                }}
                            >
                                Rating
                                <span className="ml-1">
                    {trackSort === 6 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th>Tags</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        {loader ?
                            <tr>
                                <td style={{textAlignLast: "center"}} colSpan={8}>
                                    <Spinner size="sm" className="m-auto text-center" color="success"/>
                                </td>
                            </tr>
                            : getSingleUserInterviews && getSingleUserInterviews.data.map((item) => (
                            <tbody key={item.id}>
                            <tr>
                                <td>
                                    <div style={{width: "8rem", overflow: "hidden"}}
                                         className="text-truncate font-size-14">
                                        <Link
                                            to={
                                                `#`
                                                // item?.progress === 0 || item?.createdAt >= subData?.limit_reached_at
                                                //     ? "#"
                                                //     : `/evaluation/${item?.id}`
                                            }
                                            onClick={() => {
                                                if (item?.createdAt >= subData?.limit_reached_at) {
                                                    setInterviewLimitModel({show: true})
                                                }
                                            }}
                                            style={{cursor: `${item?.progress === 0 ? "default" : "pointer"}`}}
                                            className={
                                                item?.progress === 0
                                                    ? "text-dark"
                                                    : item?.createdAt >= subData?.limit_reached_at
                                                        ? "text-danger"
                                                        : "text-primary"
                                            }
                                        >
                                            {item?.Candidate?.first_name + " " + item?.Candidate?.last_name}
                                        </Link>
                                        <span className="d-block text-muted text-truncate">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={props => renderTooltip(props, `${item?.Position?.title}`)}>

                                        <small title={item?.Position?.title}
                                               style={{cursor: "pointer"}}>{item?.Position?.title}</small>
                                    </OverlayTrigger>

                            </span>
                                    </div>
                                </td>
                                <td>{item?.is_reviewed === true ? "Evaluated" : renderStatus(item?.status)}</td>
                                <td>{item?.start_date ? moment(item?.start_date).format("MMM DD, YYYY") : "N/A"}</td>
                                <td className="">
                        <span>
                            {item?.progress}/{item?.question_count}
                        </span>
                                    <Progress className="mb-1" color="primary"
                                              value={(item?.progress / item?.question_count) * 100}/>
                                </td>
                                <td>{item?.completion_date ? moment(item?.completion_date).format("MMM DD, YYYY") : "N/A"}</td>
                                <td>
                                    <div className="mb-1">
                            <span className={`badge badge-${renderRating(item?.my_response)}`}>
                                <i className="mdi mdi-star mr-1"/> {item?.ratings ? Number(item?.ratings).toFixed(1) : 0}
                            </span>
                                    </div>
                                    <span
                                        className="d-block">{item?.my_response ? renderResponse(item?.my_response) : "Pending"}
                        </span>
                                </td>

                                <td className="align-items-center">
                                    {
                                        tagModal && tagModal.id === item.id ?
                                            <>
                                            </> : <>
                                                {renderInterviewTags(item?.Tag, item.id, null)}
                                                {item?.Tag.length < 4 ?
                                                    <span style={{position: "relative", left: "3px", top: "3px"}}>

                            </span> : null}

                                            </>
                                    }


                                </td>
                                <td className="">
                                    <i onClick={() => {
                                        setModal({
                                            show: true,
                                            title: 'remove this interview',
                                            btn: 'Remove',
                                            fn: () => {
                                                deleteInterview({
                                                    interview_id: item.id,
                                                    candidate_id: getCurrentUser.id
                                                }, () => {
                                                    setModal(undefined)
                                                    onSubmit()

                                                })
                                            }
                                        })
                                    }}
                                       className="bx bx-trash" style={{fontSize: "2rem", cursor: "pointer"}}/>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </Table>
                </CardBody>
            </Card>

            <div className="d-flex justify-content-center mb-4">
                <Pagination
                    pages={getSingleUserInterviews?.totalPages}
                    page={getSingleUserInterviews?.currentPage}
                    setPage={(page) => onSubmit(page)}
                    // setPage={(page) => console.log(page)}
                    count={getSingleUserInterviews?.totalItems}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        // Users: getUsersSelector(state),
        getCurrentUser: getSingleUserDetailSelector(state),
        getCurrentUserInterview: getSingleUserInterviewDetailSelector(state),
        getCurrentUserProfile: getSingleUserProfileDetailSelector(state),
        getSingleUserInterviews: getSingleUserInterviewsSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDetail: (data) => dispatch(getUserDetailAction(data)),
        addNewInterview: (data, cb) => dispatch(addCandidateInterviewAction(data, cb)),
        deleteInterview: (data, cb) => dispatch(deleteCandidateInterviewAction(data, cb)),

        getUserInterviews: (data, cb) => dispatch(getCandidateInterviewsAction(data, cb)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Interviews))
