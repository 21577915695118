import React, { useState, useRef, useCallback } from "react"
import {
  Row,
  Col
} from "reactstrap";
import { getRolesSelector } from "../../../redux/selectors/AdminSelector";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';

const TeamMemberFilter = (props) => {
  const { Roles, query, setQuery } = props
  const formRef = useRef(null);

  const nameChange = (value) => {
    if (value.keyCode === 13)
      setQuery({
        ...query,
        member_name: value.target.value,
      });
  };
  const emailChange = (value) => {
    if (value.keyCode === 13)
      setQuery({
        ...query,
        member_email: value.target.value,
      });
  };

  const phoneChange = (value) => {
    if (value.keyCode === 13)
      setQuery({
        ...query,
        member_phone: value.target.value,
      });
  };

  const clearFilters = () => {
    setQuery({});
    formRef.current.reset();
  };

  return (
    
    <div className="card candidate-filters  border border-primary">
      <div className="card-header">
        <h5 className="card-title">Team Member Filters</h5>
      </div>
      <form ref={formRef} className=" card-body pb-0 ">
        <Row>
          <Col lg={10}>
            <Row>
              <Col lg={3}>
                <div className="form-group">
                  <input
                    name="name"
                    placeholder="Name"
                    className="form-control"
                    onKeyUp={(e) => nameChange(e)}
                    type="text"
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <input
                    name="email"
                    placeholder="Email-Address"
                    className="form-control"
                    onKeyUp={(e) => emailChange(e)}
                    type="text"
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <input
                    name="phone"
                    placeholder="Phone Number"
                    className="form-control"
                    onKeyUp={(e) => phoneChange(e)}
                    type="text"
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group">
                  <select
                    name="roles"
                    className="form-control"
                    type="select"
                    onChange={(e) => setQuery({ ...query, member_role: e.target.value })}
                  >
                    <option value="">Roles</option>
                    {Roles.Roles && Roles?.Roles?.map((role) => <option key={`company-detail-team-member-${role.id}`} value={role.id}>{role.name}</option>)}
                  </select>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={2}>
            <Row className="mb-3">
              <Col lg={12}>
                <div className="form-group">
                  <button type="button" className="btn btn-secondary btn-block" onClick={() => clearFilters()}>
                    Clear Filters
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    Roles: getRolesSelector(state),
  }
}

export default (connect(mapStateToProps, null)(TeamMemberFilter));
