import React, {Fragment, useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import pic1 from "../../../images/default.svg";
import {Dropdown} from "react-bootstrap";
import ContactSlider from "../zenix/Home/ContactSlider";
import IndexTab from "../zenix/Home/IndexTab";
import {Row, Col, Card, Table} from "react-bootstrap";

//Images

import Card1 from "./../../../images/card/card1.jpg";
import Card2 from "./../../../images/card/card2.jpg";
import Card3 from "./../../../images/card/card3.jpg";
import Card4 from "./../../../images/card/card4.jpg";
import {
    getAnalyticalCandidateRegistrationDashboardSelector,
    getAnalyticalDashboardSelector,
    getAnalyticalEmployerRegistrationDashboardSelector,
    getBillingDashboardSelector,
    getBillingInvoiceGenerationDashboardSelector
} from "../../../redux/selectors/AdminSelector";
import {
    getAnalyticalCandidateRegistrationDashboardAction,
    getAnalyticalDashboardAction,
    getAnalyticalEmployerRegistrationDashboardAction,
    getBillingDashboardAction,
    getBillingInvoiceGenerationDashboardAction
} from "../../../redux/actions/AdminActions";
import {connect} from "react-redux";
import InvoiceTable from "./InvoiceTable";

const ApexPie = loadable(() => pMinDelay(import("./PieEvaluations"), 1000));
const DailyWeeklyMonthly = loadable(() =>
    pMinDelay(import("./DailyWeeklyMonthly"), 1000)
);
const BarCompanyPlans = loadable(() =>
    pMinDelay(import("./BarSubscriptions"), 1000)
);
const BarExpireSubscriptions = loadable(() =>
    pMinDelay(import("./BarExpireSubscriptions2"), 1000)
);
const ApexCandidate = loadable(() => pMinDelay(import("./candidate"), 1000));
const EmployersLefted = loadable(() =>
    pMinDelay(import("./EmployersLefted"), 1000)
);
const InvoicesGenerated = loadable(() =>
    pMinDelay(import("./InvoicesGenerated"), 1000)
);

const MarketOverviewChart = loadable(() =>
    pMinDelay(import("../zenix/Home/MarketOverviewChart"), 1000)
);
const CurrentChart = loadable(() =>
    pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
);



// const InvoiceTable = loadable(() =>
//     pMinDelay(import("./InvoiceTable"), 1000)
// );

const cardBox = [
    {image: Card1},
    {image: Card2},
    {image: Card3},
    {image: Card4},
];

const orderdataBlog = [
    {price: "82.1", amount: "58.9", total: "134.10"},
    {price: "85.2", amount: "55.8", total: "136,12"},
    {price: "87.3", amount: "53.7", total: "138,12"},
    {price: "89.4", amount: "51.6", total: "139,12"},
    {price: "91.9", amount: "47.1", total: "140,12"},
    {price: "93.8", amount: "46.2", total: "142,12"},
    {price: "94.7", amount: "45.3", total: "145,12"},
    {price: "97.6", amount: "44.4", total: "147,12"},
];

const Billing = (props) => {
    const [crrency1, setCrrency1] = useState("Monthly (2021)");
    const [country1, setCountry1] = useState("Medan, IDN");
    const [invoice_filter, set_invoice_filter] = useState("daily")

    useEffect(() => {
        props.loadBillingDashboard();
        props.loadBillingInvoiceGenerationDashboard({
            filter_type: invoice_filter
        });

    }, [])
    useEffect(() => {
        props.loadBillingInvoiceGenerationDashboard({
            filter_type: invoice_filter
        });
    }, [invoice_filter])

    const {
        getBillingDashboard,
        getBillingInvoiceGenerationDashboard
    } = props;
    return (
        <Fragment>
            <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                <h2 className="font-w600 mb-2 mr-auto ">Dashboard</h2>
            </div>

            <div className="row">
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-success">
                        <div className="card-body p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="flaticon-381-diamond"></i>
                                </span>
                                <div className="media-body text-white text-right">
                                    <p className="mb-1">Invoices Paid</p>
                                    <h3 className="text-white">{getBillingDashboard?.invoice_succeeded || '0'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-info">
                        <div className="card-body p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="flaticon-381-heart"></i>
                                </span>
                                <div className="media-body text-white text-right">
                                    <p className="mb-1">Invoices Outstanding</p>
                                    <h3 className="text-white">{getBillingDashboard?.invoice_processing || '0'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card bg-primary">
                        <div className="card-body p-4">
                            <div className="media">
                                <span className="mr-3">
                                <i className="flaticon-381-user-7"></i>
                                </span>
                                <div className="media-body text-white text-right">
                                    <p className="mb-1">Invoices Disputed</p>
                                    <h3 className="text-white">{getBillingDashboard?.invoice_payment_failed || '0'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row sparkboxes mt-4 mb-4 d-none">
                <div class="col-md-4">
                    <div className="card">
                        <div className="card-body pb-0">
                            <h2>
                                {" "}
                                140 <i className="fa fa-circle text-primary"></i>
                            </h2>
                            Invoices Paid
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div className="card">
                        <div className="card-body pb-0">
                            <h2>
                                <i className="fa fa-circle text-primary"></i> 264
                            </h2>
                            Invoices Outstanding
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div className="card">
                        <div className="card-body pb-0">
                            <h2>
                                <i className="fa fa-circle text-primary"></i> 230
                            </h2>
                            Invoices Disputed
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Card>
                        <Card.Header>
                            <h5 className="card-title">Invoices Generated</h5>
                        </Card.Header>
                        <Card.Body>
                            <DailyWeeklyMonthly 
                                data={getBillingInvoiceGenerationDashboard} 
                                activeTabToggle={set_invoice_filter}
                                name={'Invoice'}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-12">
                    <InvoiceTable getBillingDashboard={getBillingDashboard}/>
                </div>
            </div>
            {/* <div className="row">
        <div className="col-xl-5 col-sm-6">
          <Card>
            <Card.Header>
              <h5 className="card-title">Subscriptions</h5>
            </Card.Header>
            <Card.Body>
              <BarCompanyPlans />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-7 col-sm-6">
          <Card>
            <Card.Header>
              <h5 className="card-title">Invoices</h5>
            </Card.Header>
            <Card.Body>
              <BarExpireSubscriptions />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-12 col-lg-12 col-sm-6">
          <Card>
            <Card.Header>
              <h5 className="card-title">Candidates Stats</h5>
            </Card.Header>
            <Card.Body>
              <ApexCandidate />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-7 col-sm-6">
          <Card>
            <Card.Header>
              <h5 className="card-title">Employers</h5>
            </Card.Header>
            <Card.Body>
              <EmployersLefted />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-5 col-sm-6">
          <Card>
            <Card.Header>
              <h5 className="card-title">Invoices Generated</h5>
            </Card.Header>
            <Card.Body>
              <InvoicesGenerated />
            </Card.Body>
          </Card>
        </div>
      </div> */}
        </Fragment>
    );
};
export {orderdataBlog};
const mapStateToProps = (state) => {
    return {
        getBillingDashboard: getBillingDashboardSelector(state),
        getBillingInvoiceGenerationDashboard: getBillingInvoiceGenerationDashboardSelector(state),
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        loadBillingDashboard: () => dispatch(getBillingDashboardAction()),
        loadBillingInvoiceGenerationDashboard: (data) => dispatch(getBillingInvoiceGenerationDashboardAction(data)),

    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));

