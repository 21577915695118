import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from "./redux/store/Store"
import { ToastContainer } from "react-toastify"

import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContextProvider from "./context/ThemeContext"

ReactDOM.render(
  <React.StrictMode>
    <Provider store = {store}>
      <SimpleReactLightbox>
        <BrowserRouter basename='/'>
          <ThemeContextProvider>
            <App />
            <ToastContainer />
          </ThemeContextProvider>
        </BrowserRouter>
      </SimpleReactLightbox>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
