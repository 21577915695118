import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { setAuthData } from "../../redux/actions/AuthActions"
import { getAuthLoadingSelector } from "../../redux/selectors/AuthSelector"
import Loader from "../components/common/Loader"
import login from "../../images/login-left.svg"
import LogoDark from "../../images/logo-dark.svg"

const Login = (props) => {
  const { AuthDataLoading, setAuthDataAction, history } = props
  // const navigate = useHistory()
  const [email, setEmail] = useState("")
  let errorsObj = { email: "", password: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const onLogin = (e) => {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (email === "") {
      errorObj.email = "Email is Required"
      error = true
    }
    if (password === "") {
      errorObj.password = "Password is Required"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }
    const data ={
      email,
      password,
      type:'admin'
    };

    setAuthDataAction( data, history, (res) => {
        if (res?.error) {
          setError(res.error)
        }
      }
    )
  }

  return (
    <div className="login-main-page">
      <div className="login-wrapper">
        <div className="login-aside-left sidenav" style={{ backgroundImage: "url(" + login + ")" }}>
          <Link to="/dashboard" className="login-logo">
            <img src={LogoDark} alt="" className="" />
          </Link>
          <div className="login-description" style={{ paddingBottom: "0px" }}>
            <ul className="social-icons mt-3">
              <li>
                  <a href="https://www.facebook.com/DigitalHireInc/" target="_blank">
                      <i className="fa fa-facebook"></i>
                  </a>
              </li>
              <li>
                  <a href="https://twitter.com/digitalhireinc" target="_blank">
                      <i className="fa fa-twitter"></i>
                  </a>
              </li>
              <li>
                  <a href="https://www.linkedin.com/company/digital-hire-inc/" target="_blank">
                      <i className="fa fa-linkedin"></i>
                  </a>
              </li>
              <li>
                  <a href="https://www.instagram.com/digitalhireinc/" target="_blank">
                      <i className="fa fa-instagram"></i>
                  </a>
              </li>
            </ul>
            <div className="mt-3">
              <a href="https://digitalhire.com/privacy-policy/" className="text-black mr-4"
                  target="_blank">
                  Privacy Policy
              </a>
              <a href="https://digitalhire.com/terms-of-service/" className="text-black mr-4"
                  target="_blank">
                  Terms & Conditions
              </a>
              <Link to={"#"} className="text-black">
                © {new Date().getFullYear()} DigitalHire
              </Link>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-white mb-1">Admin's Dashboard</h3>
                        <p className="">Sign in by entering information below</p>
                      </div>
                      {error && <div className="animated fadeInUp text-danger fs-15 text-center"> {error}</div>}
                      <form onSubmit={onLogin}>
                        <div className="form-group mb-3">
                          <label className="mb-2 ">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                        </div>
                        <div className="form-group mb-3">
                          <label className="mb-2 ">
                            <strong>Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 ">
                              <input type="checkbox" className="custom-control-input mr-2" id="basic_checkbox_1" />
                              <label className="custom-control-label" htmlFor="basic_checkbox_1">
                                Remember my preference
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button disabled={AuthDataLoading} type="submit" className="btn btn-primary btn-block">
                            {AuthDataLoading ? <Loader spinnerClass="text-white" /> : "Sign In"}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-2">
                        <p>
                          Don't have an account?{" "}
                          <Link className="text-primary" to={{ pathname: "./register", account: "new" }}>
                            Sign up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    AuthDataLoading: getAuthLoadingSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthDataAction: (data, history, cb) => dispatch(setAuthData(data, history, cb)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
