import React, {useEffect, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
} from "reactstrap"
import TeamMember from "./TeamMember";
import Position from "./Position";
import Subscription from "./Subscription";
import Logs from "./../common/logs/Logs";
import {Modal} from "react-bootstrap"
import pic1 from "../../../images/default.svg"
import Switch from "../common/Switch"
import DeletePrompt from "../common/DeletePrompt"
import SendInviteModal from "./../common/SendInvite"
import JSONPretty from "react-json-prettify"
import github from "react-json-prettify/dist/themes/googlecode"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    getCompanyProfileAction,
    updateCompanyStatusAction,
    updateCompanySubscriptionAction
} from "../../../redux/actions/AdminActions";
import {getCompanyProfileSelector} from "../../../redux/selectors/AdminSelector";
import moment from "moment";
import EditUserProfile from "../Users/EditUserProfile";
import EditSubscription from "./EditSubscription";

const CompanyDetails = (props) => {
    const [companyId, setCompanyId] = useState(String(props?.location?.pathname).split("/company-detail/")[1])

    const [addSubscription, setAddSubscription] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)
    const [statsModal, setStatsModal] = useState(false)

    const {loadCompanyProfileData, companyProfileData, updateCompanyStatus, updateCompanySubscription} = props;
    const [status, setStatus] = useState(companyProfileData?.Company?.status ? companyProfileData?.Company?.status : false)
    const [limit, setLimit] = useState({})
    useEffect(() => {
        setStatus(companyProfileData?.Company?.status)
        // console.log(companyProfileData)
        // // console.log(JSON.parse(companyProfileData?.Company?.Subscription?.limit))
        if (companyProfileData?.Company?.Subscription?.limit) {
            setLimit(JSON.parse(companyProfileData?.Company?.Subscription?.limit))
        }
    }, [companyProfileData])

    useEffect(() => {
        const id = String(props?.location?.pathname).split("/company-detail/")[1];
        setCompanyId(id);
        if (id) {
            loadCompanyProfileData({company_id: id});
        } else {
            props.history.push("/companies");
        }
    }, [props?.location?.pathname])

    const updateStatus = (s) => {
        const data = {
            "status": s,
            "company_id": companyProfileData?.Company.id
        }
        updateCompanyStatus(data)

    }
    return (
        <>
            <Card>
                <CardBody>
                    <Row xl={12}>
                        <Col xl={4}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <h4>Basic Information</h4>
                                    <hr/>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <img src={companyProfileData?.Company?.logo || pic1} style={{maxWidth: '50%'}}
                                         alt="user image" className="img-thumbnail rounded-circle profile-image"/>
                                    <h5 className="mt-4">{companyProfileData?.Company?.name}</h5>
                                    {/* <span className="d-block mb-1">
                                        <i className="bx bx-phone-call"></i>&nbsp;{companyProfileData?.Company?.name}
                                    </span> */}
                                    <span className="d-block mb-1">
                                        <i className="bx bx-mail-send"></i>&nbsp;{companyProfileData?.Company?.email}
                                    </span>
                                    <span className="d-block mb-1">
                                        <i className="mdi mdi-calendar-blank green mr-2"></i> {moment(companyProfileData?.Company?.updatedAt).format("MMM DD, YYYY")}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <h4>Subscription</h4>
                                    <hr/>
                                </div>
                                <div>
                                    <p>Current: {companyProfileData?.Company?.Subscription?.name}</p>

                                    <p>Interviews: {limit?.interviews?.used}/{limit?.interviews?.allowed}</p>
                                    <p>Users: {limit?.users?.used}/{limit?.users?.allowed}</p>
                                    <p>Positions: {limit?.positions?.used}/{limit?.positions?.allowed}</p>
                                </div>
                                {/* <div>
                                   <div className="form-group">
                                       <select name="subscription_name" className="form-control" type="select">
                                           <option value="">Change Subscription</option>
                                           <option value="trial">trial</option>
                                           <option value="pro">pro</option>
                                            <option value="proPlus">proPlus</option>
                                            <option value="premium">premium</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div>
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => {
                                        setAddSubscription(true)
                                    }}>
                                        Change Subscription
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <h4>Status</h4>
                                    <hr/>
                                </div>
                                <div>
                                    <p>Current: Active</p>
                                </div>
                                <div>
                                    <Switch
                                        isOn={status}
                                        forId={`company-detail-${"company-id"}`}
                                        handleToggle={() => {
                                            updateStatus(!status)
                                            setStatus(!status)
                                        }}
                                    />
                                </div>
                                {/* <div>
                                    <button type="button" className="btn btn-primary btn-block">
                                        Save Change
                                    </button>
                                    </div> */}
                                {/* <div style={{ marginTop: "5rem", width: "100%" }}>
                                    <button type="button" className="btn btn-danger btn-block">
                                        Reset Company Password
                                    </button>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <TeamMember
                setInviteModal={setInviteModal}
                setModalShow={setModalShow}
                companyId={companyId}
            />

            <Position
                modalShow={modalShow}
                setModalShow={setModalShow}
                companyId={companyId}
            />

            <Subscription companyId={companyId}/>

            <Logs
                statsModal={statsModal}
                setStatsModal={setStatsModal}
                companyId={companyId}
            />

            <Modal
                centered={true}
                show={modalShow?.show}
                onHide={() => {
                    setModalShow(undefined)
                }}
            >
                <DeletePrompt
                    title={`Are you sure you want to delete this ${modalShow?.msg}?`}
                    btnText={"Delete"}
                    func={() => modalShow?.fn()}
                    toggle={() => {
                        setModalShow(undefined)
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                // size="md"
                show={inviteModal}
                onHide={() => {
                    setInviteModal(!inviteModal)
                }}
            >
                <SendInviteModal
                    toggle={() => {
                        setInviteModal(!inviteModal)
                    }}
                    showAlert={() => console.log(true)}
                    company={companyProfileData?.Company}
                />
            </Modal>
            <Modal
                centered={true}
                size="lg"
                show={statsModal}
                onHide={() => {
                    setStatsModal(false)
                }}
            >
                <Card>
                    <CardBody>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>URL</th>
                                <th>PARAMETERS</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>/dashboard</td>
                                <td>
                                    <JSONPretty
                                        json={{
                                            company: "b63549e7-3771-4a90-98b9-6047185a4c86",
                                            pagination: {page: 1, size: 10},
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        <Table responsive className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th>STATE</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <JSONPretty
                                        json={{
                                            name: "Sadiq Public School",
                                            industry: "Education Management",
                                            logo: "blob:https://employers.digitalhire.net/35a5d67d-34b3-4f4d-9c4e-d83e7c22156f",
                                            email: "robas@yahoo.com",
                                            website: "www.youtube.com",
                                            address: "Address 1",
                                            address2: "Address 2",
                                            city: "Islamabad",
                                            state: "Islamabad",
                                            country: "PK",
                                            postal_code: "46000",
                                        }}
                                        theme={github}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Modal>
            <Modal
                centered={true}
                show={addSubscription}
                onHide={() => {
                    setAddSubscription(false)
                }}
            >
                <EditSubscription subscription={companyProfileData?.Company?.Subscription}
                                  updateSubscription={updateCompanySubscription}
                                  companyId={companyId}
                                  toggle={() => {
                                      setAddSubscription(false)
                                  }}/>
            </Modal>
        </>
    )
}

// const jsonStyle = {
//   display: "grid",
//   grid-template-columns: "1fr 100px 1fr",
//   align-items: "center",
//   height: "100vh",
//   box-sizing: "border-box",
//   padding: "20px",
//   gap: "20px";
// }

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyProfileData: (data) => dispatch(getCompanyProfileAction(data)),
        updateCompanyStatus: (data) => dispatch(updateCompanyStatusAction(data)),
        updateCompanySubscription: (data, cb) => dispatch(updateCompanySubscriptionAction(data, cb)),
    }
}

const mapStateToProp = (state) => {
    return {
        companyProfileData: getCompanyProfileSelector(state),

    }
}

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(CompanyDetails))
