/// Menu
import MetisMenu from "metismenujs"
import React, {Component, useContext, useEffect} from "react"
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar"
/// Link
import {Link} from "react-router-dom"
import useScrollPosition from "use-scroll-position"
import {ThemeContext} from "../../../context/ThemeContext"
import profile from "../../../images/Untitled-1.jpg"
import "./sidebar.css"

class MM extends Component {
    componentDidMount() {
        this.$el = this.el
        this.mm = new MetisMenu(this.$el)
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

const SideBar = () => {
    const {iconHover, sidebarposition, headerposition, sidebarLayout} = useContext(ThemeContext)
    useEffect(() => {
        var btn = document.querySelector(".nav-control")
        var aaa = document.querySelector("#main-wrapper")

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle")
        }

        btn.addEventListener("click", toggleFunc)
    }, [])
    let scrollPosition = useScrollPosition()
    /// Path
    let path = window.location.pathname
    path = path.split("/")
    path = path[path.length - 1]
    /// Active menu
    let deshBoard = ["analytics", "billing", ""],
        position = ["position", "companies"],
        users = ["users"],
        support = ["supports"],
        media = ["media"],
        candidate = ["candidate"],
        evaluation = ["evaluation"],
        report = ["report"]
    return (
        <div
            className={`deznav ${iconHover} ${
                sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static"
                    ? scrollPosition > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                {/* <div className="main-profile">
          <img src={profile} alt="" />
          <Link to={"#"}>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </Link>
          <h5 className="mb-0 fs-20 text-black ">
            <span className="font-w400">Hello,</span> John
          </h5>
          <p className="mb-0 fs-14 font-w400">john@digitalhire.com</p>
        </div> */}
                <MM className="metismenu" id="menu">

                <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                    <Link className="ai-icon" to={"/analytics"}>
                        <i className="bx bxs-buildings"></i>
                        <span className="nav-text">Dashboard</span>
                    </Link>
                    {/* <ul>
              <li>
                <Link className={`${path === "administration" ? "mm-active" : ""}`} to="/administration">
                  Administration
                </Link>
              </li>
            </ul> */}
                </li>
                    <li className={`${position.includes(path) ? "mm-active" : ""}`}>
                        <Link className="ai-icon" to={"/companies"}>
                            <i className="bx bxs-buildings"></i>
                            <span className="nav-text">Companies</span>
                        </Link>
                        {/* <ul>
              <li>
                <Link className={`${path === "administration" ? "mm-active" : ""}`} to="/administration">
                  Administration
                </Link>
              </li>
            </ul> */}
                    </li>
                    <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                        <Link className="ai-icon" to={"/users"}>
                            <i className="bx bxs-user-detail"></i>
                            <span className="nav-text">Users</span>
                        </Link>
                    </li>
                    <li className={`${support.includes(path) ? "mm-active" : ""}`}>
                        <Link className="ai-icon" to={"/support"}>
                            <i className="bx bxs-user-detail"></i>
                            <span className="nav-text">Support</span>
                        </Link>
                    </li>
                    {/* <div className="main-profile mt-3" style={{padding: 0, paddingLeft: "50px", textAlign: "left"}}>
                        <h6 className="text-subtitle">Settings</h6>
                    </div>

                    <li className={""}>
                        <Link className="ai-icon" to={"#"}>
                            <i className="bx bx-user-circle"></i>
                            <span className="nav-text">My Account</span>
                        </Link>
                    </li> */}
                </MM>
                <div className="copyright">
                    <p>
                        2022 © <strong>DigitalHire</strong>
                    </p>
                </div>
            </PerfectScrollbar>
        </div>
    )
}


export default SideBar
