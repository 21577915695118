import React, {useState, useEffect} from "react"
import {
    Row,
    Col,
    Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    ModalFooter,
    Input,
    Form,
    FormGroup,
    Label,
    Table,
    Spinner,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Badge
} from "reactstrap"
import {Modal} from "react-bootstrap"
import Pagination from "../common/Pagination/Pagination"
import {withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {
    getUsers,
    getRoles,
    userChangeActiveStatusAction,
    userPasswordResetAction
} from "../../../redux/actions/AdminActions"
import {getUsersSelector, getUserDataLoadingSelector, getRolesSelector} from "../../../redux/selectors/AdminSelector"
import pic1 from "../../../images/default.svg"
import DeletePrompt from "../common/DeletePrompt"
import UserFilter from "./UserFilter"
import moment from "moment"


const Users = (props) => {
    const {
        Users,
        UserDataLoading,
        Roles,
        getUsersAction,
        getRolesAction,
        userChangeActiveStatus,
        userPasswordReset
    } = props
    const [promptModal, setPromptModal] = useState({})
    const [modalShow, setModalShow] = useState(false)
    const [query, setQuery] = useState({})
    const [sort, setSort] = useState(["createdAt", "DESC"])
    const [trackSort, setTrackSort] = useState(0)

    useEffect(() => {
        getRolesAction()
    }, []);

    useEffect(() => {
        onSubmit();
    }, [sort, query]);

    const onSubmit = (page = 1) => {
        console.log("Query: ", query)
        const data = {
            filter_options: {
                ...query,
                sort_array: [sort],
            },
            pagination: {
                page: page,
                size: 10,
            },
        }
        getUsersAction(data)
    }

    console.log("Prompt Data: ", promptModal)
    return (
        <>
            <UserFilter query={query} setQuery={setQuery}/>

            {/*<div className="d-flex justify-content-end mr-1">*/}
            {/*    <div className="form-group col-2">*/}
            {/*        <button className="btn btn-primary btn-block" onClick={() => setModalShow(true)}>*/}
            {/*            Export*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="card">
                <div className="card-body">
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "first_name" && prev[1] === "ASC"
                                            ? ["first_name", "DESC"]
                                            : ["first_name", "ASC"]
                                    })
                                    setTrackSort(1)
                                }}
                            >
                                Name
                                <span className="ml-1">
                    {trackSort === 1 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "email" && prev[1] === "ASC" ? ["email", "DESC"] : ["email", "ASC"]
                                    })
                                    setTrackSort(2)
                                }}
                            >
                                Email
                                <span className="ml-1">
                    {trackSort === 2 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "role" && prev[1] === "ASC" ? ["role", "DESC"] : ["role", "ASC"]
                                    })
                                    setTrackSort(3)
                                }}
                            >
                                Role
                                <span className="ml-1">
                    {trackSort === 3 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "createdAt" && prev[1] === "ASC" ? ["createdAt", "DESC"] : ["createdAt", "ASC"]
                                    })
                                    setTrackSort(4)
                                }}
                            >
                                Created Date
                                <span className="ml-1">
                    {trackSort === 4 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "verified" && prev[1] === true ? ["verified", false] : ["verified", true]
                                    })
                                    setTrackSort(5)
                                }}
                            >
                                Verified
                                <span className="ml-1">
                    {trackSort === 5 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "active" && prev[1] === true ? ["active", false] : ["active", true]
                                    })
                                    setTrackSort(6)
                                }}
                            >
                                Active
                                <span className="ml-1">
                    {trackSort === 6 ? (
                        sort[1] === "DESC" ? (
                            <i className="fa fa-arrow-down"/>
                        ) : (
                            <i className="fa fa-arrow-up"/>
                        )
                    ) : (
                        ""
                    )}
                  </span>
                            </th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        {UserDataLoading ? (
                            <tbody>
                            <tr>
                                <td style={{textAlignLast: "center"}} colSpan={9}>
                                    <Spinner size="sm" className="m-auto text-center" color="success"/>
                                </td>
                            </tr>
                            </tbody>
                        ) : Users?.Users?.data.length ? (
                            Users?.Users?.data?.map((item) => (
                                <tbody key={`user-listing-${item.id}`}>
                                <tr>
                                    <td style={{width: '37px'}}>
                                        <img className="rounded-circle" width="35" src={item?.image || pic1}
                                             alt=""/>{" "}
                                    </td>
                                    <td>
                                        {item.Roles[0].name === "candidate"
                                            ?
                                            <Link style={{pointer: "cursor"}} to={`/user-detail/${item?.id}`}>
                                                {item?.first_name + " " + item?.last_name}
                                            </Link>
                                            :
                                            <Link style={{pointer: "cursor"}} to={`/employer-detail/${item?.id}`}>
                                                {item?.first_name + " " + item?.last_name}
                                            </Link>
                                        }

                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.Roles[0].name}</td>
                                    <td>{item.createdAt ? moment(item.createdAt).format("MMM DD, YYYY") : 'N/A'}</td>
                                    <td>{item.verified ? <Badge color={"light"} className="font-size-12">True</Badge> :
                                        <Badge color={"danger"} className="font-size-12">False</Badge>}</td>
                                    <td>{item.is_active ? <Badge color={"light"} className="font-size-12">True</Badge> :
                                        <Badge color={"danger"} className="font-size-12">False</Badge>}</td>
                                    <td>
                                        <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                <i className="mdi mdi-dots-horizontal font-size-18"/>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={() => {
                                                    console.log("HEHE")
                                                    setPromptModal({
                                                        show: true,
                                                        title: 'Are you sure you want to reset this account password?',
                                                        message: `An auto generated password will be sent to ${item.email} email.`,
                                                        btn: 'Reset',
                                                        fn: () => {
                                                            userPasswordReset({
                                                                user_id: item?.id
                                                            }, () => {

                                                                // onSubmit()
                                                            })
                                                            setPromptModal(false)
                                                        }
                                                    })
                                                }}>

                                                    Password Reset
                                                </DropdownItem>
                                                <DropdownItem className="text-dark" onClick={() => setPromptModal({
                                                    show: true,
                                                    title: `Are you sure you want to ${item.is_active ? 'deactivate' : 'activate'} this account?`,
                                                    message: ``,
                                                    btn: item.is_active ? 'Deactivate' : 'Activate',
                                                    fn: () => {

                                                        userChangeActiveStatus({
                                                            user_id: item?.id,
                                                            status: item.is_active ? 0 : 1
                                                        }, (bol) => {
                                                            if (bol) onSubmit();
                                                            setPromptModal(undefined)
                                                        })
                                                    }
                                                })}>
                                                    {item.is_active ? 'Account Deactivate' : 'Account Activate'}
                                                </DropdownItem>
                                                {/*<DropdownItem className="text-dark"> Plan Change</DropdownItem>*/}

                                                {/*<DropdownItem className="text-danger">Duplicate Accounts</DropdownItem>*/}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                                </tbody>
                            ))
                        ) : (
                            <tbody>
                            <tr>
                                <td style={{textAlignLast: "center"}} colSpan={9}>
                                    No Data To Display
                                </td>
                            </tr>
                            </tbody>
                        )}
                    </Table>
                </div>
            </div>

            <Modal
                centered={true}
                show={promptModal?.show}
                onHide={() => {
                    setPromptModal(undefined)
                }}
            >
                <DeletePrompt
                    title={promptModal?.title}
                    message={promptModal?.message}
                    btnText={promptModal?.btn}
                    func={() => promptModal?.fn()}
                    toggle={() => {
                        setPromptModal(undefined)
                    }}
                />
            </Modal>
            <div className="d-flex justify-content-center">
                {!UserDataLoading && (
                    <Pagination
                        pages={Users?.Users?.totalPages}
                        page={Users?.Users?.currentPage}
                        setPage={(page) => onSubmit(page)}
                        count={Users?.Users?.totalItems}
                    />
                )}
            </div>


            {/* <Modal
          centered={true}
          show={account}
          onHide={() => {
            setAccount(false)
          }}
      >
        <DeletePrompt
            title={""}
            btnText={"Deactivate"}
            func={() =>console.log('asd')}
            toggle={() => {
              setAccount(false)
            }}
        />
      </Modal> */}
            <Modal
                // size="lg"
                // aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <div className="p-3">
                    <Row>
                        <Col lg={12} className="text-center mt-2">
                            <h4>{'Select Your Desire Columns'}</h4>
                            {/* <h6 className="mt-3">{message}</h6> */}
                        </Col>
                    </Row>
                    <Modal.Body>
                        <Form>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Names
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Address
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Phone
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Role
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Created Date
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Verified
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox"/> Status
                                </Label>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Row className="justify-content-center mb-2">
                        {/* <Col className="text-center" lg={8}> */}
                        <button
                            type="button"
                            onClick={() => {
                                setModalShow(false)
                            }}
                            style={{paddingInline: '25px'}}
                            className="btn btn-outline-secondary mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setModalShow(false)
                            }}
                            style={{paddingInline: '30px'}}
                            className={`btn btn-primary ml-2`}
                        >
                            Export
                        </button>
                        {/* </Col> */}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        Users: getUsersSelector(state),
        UserDataLoading: getUserDataLoadingSelector(state),
        Roles: getRolesSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUsersAction: (data) => dispatch(getUsers(data)),
        getRolesAction: () => dispatch(getRoles()),
        userChangeActiveStatus: (data, cb) => dispatch(userChangeActiveStatusAction(data, cb)),
        userPasswordReset: (data, cb) => dispatch(userPasswordResetAction(data, cb)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
