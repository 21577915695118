import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, Col, Media, Row, Spinner} from "reactstrap"
import PhoneInput from "react-phone-input-2"
import avatar from "../../../images/default.svg"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {
    addCompanySupportMembersAction,
    addSupportMembersAction,
    getCompaniesListAction
} from "../../../redux/actions/AdminActions";
import {
    getCompanyListSelector,
    getRolesSelector,
    getSupportMembersSelector,
    getUserDataLoadingSelector
} from "../../../redux/selectors/AdminSelector";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const SendInviteModal = (props) => {

    const {toggle, addSupportMember, addCompanySupportMember, companyList, reFreshData} = props
    let errorsObj = {email: "", fName: "", lName: "", phone: "", role: "", expire: "", company: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [email, setEmail] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompanyId] = useState('');
    const [expire, setExpireDate] = useState(null);
    const [loader, setLoader] = useState(false);


    // useEffect(() => {
    //     getCompaniesList();
    //     resetFields();
    // }, [])

    const resetFields = () => {
        setEmail('');
        setFName(''); 
        setLName('');
        setPhone(''); 
        setCompanyId('');
        setExpireDate(null); 
        setLoader(false);
    }
    const onsubmit = (e) => {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (email === '') {
            errorObj.email = "Email Address is Required"
            error = true
        }
        if (fName === '') {
            errorObj.fName = "First Name is Required"
            error = true
        }
        if (lName === '') {
            errorObj.lName = "Last Name is Required"
            error = true
        }
        if (phone === '') {
            errorObj.phone = "Phone Number is Required"
            error = true
        }
        if (company === '') {
            errorObj.company = "Company is Required"
            error = true
        }
        if (expire === '' || expire === null) {
            errorObj.expire = "Expire date is Required"
            error = true
        }
        setErrors(errorObj)
        console.log({errorObj});
        if (error) {
            return
        }
        const data = {
            user_data: {
                first_name: fName,
                last_name: lName,
                phone: phone,
                email: email
            }
        }

        setLoader(true)
        addSupportMember(data, props, (user_id) => {
            if(user_id){
                addCompanySupportMember({
                    "company_id": company,
                    "user_id": user_id,
                    "expire_date": expire
                }, props, ()=> {
                    setLoader(false);
                    resetFields();
                    reFreshData();
                });
            }else {
                setLoader(false);
            }
        });
    }

    return (
        <Card className="overflow-hidden">
            <CardBody>
                <Row><Col>
                    <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>close</button>
                </Col></Row>
                <Row className="align-items-center ">
                    <Col sm="8">
                        <div className="">
                            <span className="text-subtitle mb-2">Add Support Member</span>
                            <span className="d-block mb-1">An email invitation will be sent.</span>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col lg={12}>
                        <div className="form-group">
                            <label className="text-label">Email <span
                                className="text-muted"><small>Required</small></span></label>
                            <input type="email" className="form-control" value={email} placeholder="Enter Email Address"
                                   onChange={(e) => setEmail(e.target.value)}/>
                            {errors.email && <div className="animated fadeInUp text-danger fs-12"
                                                  style={{marginTop: 5}}>{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <label className="text-label">First Name <span
                                className="text-muted"><small>Required</small></span></label>
                            <input type="text" className="form-control" value={fName} placeholder="Enter First Name"
                                   onChange={(e) => setFName(e.target.value)}/>
                            {errors.fName && <div className="animated fadeInUp text-danger fs-12"
                                                  style={{marginTop: 5}}>{errors.fName}</div>}
                        </div>
                        <div className="form-group">
                            <label className="text-label">Last Name <span className="text-muted"><small>Required</small></span></label>
                            <input type="text" className="form-control" value={lName} placeholder="Enter Last Name"
                                   onChange={(e) => setLName(e.target.value)}/>
                            {errors.lName && <div className="animated fadeInUp text-danger fs-12"
                                                  style={{marginTop: 5}}>{errors.lName}</div>}
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="form-group">
                            <label>Phone <span className="text-muted"><small>Required</small></span></label>
                            <PhoneInput
                                country={"us"}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                placeholder="541-754-3010"
                                inputStyle={{width: "99%", height: "-webkit-fill-available", border: "hidden"}}
                                containerClass={"form-control"}
                                containerStyle={{padding: 0}}
                                buttonStyle={{borderRadius: " 10px 0 0 10px", border: "0"}}
                            />
                            {errors.phone && <div className="animated fadeInUp text-danger fs-12"
                                                  style={{marginTop: 5}}>{errors.phone}</div>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-group">
                            <label>Expiry Date <span className="text-muted"><small>Required</small></span></label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    clearable
                                    format="yyyy-MM-dd"
                                    className="form-control"
                                    placeholder="Expiry Date"
                                    value={expire}
                                    onChange={(e) => setExpireDate(e) }
                                />
                            </MuiPickersUtilsProvider>
                            {errors.expire && <div className="animated fadeInUp text-danger fs-12"
                                                  style={{marginTop: 5}}>{errors.expire}</div>}
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col lg={12}>
                        <div className="form-group">
                            <label className="text-label">Select Company <span
                                className="text-muted"><small>Required</small></span></label>
                            <select
                                name="role"
                                label="Select Role"
                                className="form-control"
                                onChange={(e) => setCompanyId(e.target?.value)}
                                required
                            >
                                <option value="">Please select company</option>
                                {companyList && companyList?.Companies?.map(company => {
                                    return <option value={company.id}>{company.name}</option>
                                })}

                            </select>
                            {errors.company && <div className="animated fadeInUp text-danger fs-12"
                                                    style={{marginTop: 5}}>{errors.company}</div>}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={5}>
                        <button type="button" onClick={() => toggle()}
                                className="btn btn-block btn-outline-secondary float-right">Cancel
                        </button>
                    </Col>
                    <Col lg={5}>
                        <button 
                            className="btn btn-block btn-primary" 
                            type="submit" 
                            disabled={loader}
                            onClick={onsubmit} 
                            onSubmit={onsubmit}> 
                            { loader 
                                ? <Spinner size="sm" className="m-auto text-center" color="#001c35"/> 
                                : 'Send Invite' 
                            }
                        </button>
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        addSupportMember: (data, props, cb) => dispatch(addSupportMembersAction(data, props.history, cb)),
        addCompanySupportMember: (data, props, cb) => dispatch(addCompanySupportMembersAction(data, props.history, cb)),
        // getCompaniesList: () => dispatch(getCompaniesListAction()),
    }
}
const mapStateToProps = (state) => {
    return {
        companyList: getCompanyListSelector(state)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendInviteModal))