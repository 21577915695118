import {
    GET_COMPANY_API_REQUEST,
    GET_COMPANY_API_SUCCESS,
    GET_COMPANY_API_FAILURE,
    GET_USERS_API_REQUEST,
    GET_USERS_API_SUCCESS,
    GET_USERS_API_FAILURE,
    GET_USER_DETAIL_API_REQUEST,
    GET_USER_DETAIL_API_SUCCESS,
    GET_USER_DETAIL_API_FAILURE,
    GET_PLANS_API_REQUEST,
    GET_PLANS_API_SUCCESS,
    GET_PLANS_API_FAILURE,
    GET_ROLES_API_REQUEST,
    GET_ROLES_API_SUCCESS,
    GET_ROLES_API_FAILURE,
    GET_ANALYTICAL_DASHBOARD_API_FAILURE,
    GET_ANALYTICAL_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE,
    GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE,
    GET_BILLING_DASHBOARD_API_SUCCESS,
    GET_BILLING_DASHBOARD_API_FAILURE,
    GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS,
    GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE,
    GET_COMPANY_POSITIONS_API_SUCCESS,
    GET_COMPANY_POSITIONS_API_FAILURE,
    GET_COMPANY_TEAM_MEMBERS_API_SUCCESS,
    GET_COMPANY_TEAM_MEMBERS_API_FAILURE,
    GET_COMPANY_PROFILE_API_SUCCESS,
    GET_COMPANY_PROFILE_API_FAILURE,
    GET_COMPANY_LOGS_API_SUCCESS,
    GET_COMPANY_LOGS_API_FAILURE,
    GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
    GET_COMPANY_SUBSCRIPTIONS_API_FAILURE,
    UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
    UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE,
    UPDATE_COMPANY_STATUS_API_SUCCESS,
    UPDATE_COMPANY_STATUS_API_FAILURE,
    GET_CANDIDATE_INTERVIEWS_API_SUCCESS,
    GET_USER_LOGS_API_SUCCESS,
    GET_EMPLOYER_DETAIL_API_REQUEST,
    GET_EMPLOYER_DETAIL_API_SUCCESS,
    GET_EMPLOYER_DETAIL_API_FAILURE,
    SUPPORT_MEMBERS_API_REQUEST,
    SUPPORT_MEMBERS_API_SUCCESS,
    SUPPORT_MEMBERS_API_FAILURE,
    GET_COMPANY_LIST_API_SUCCESS,
    GET_COMPANY_LIST_API_FAILURE,
    GET_COMPANY_LIST_API_REQUEST, GET_ACTIVE_USERS_API_SUCCESS,
} from "../constants/AdminConstants"

const initalState = {
    Companies: {},
    CompanyDataLoading: false,
    Users: {},
    UserDataLoading: false,
    CompaniesList: undefined,
    CompaniesListDataLoading: undefined,
    SupportMembers: {},
    SupportMembersDataLoading: false,
    userDetail: {},
    UserDetailDataLoading: false,
    Plans: {},
    PlanDataLoading: false,
    Roles: {},
    RoleDataLoading: false,
    AnalyticalDashboard: {},
    AnalyticalDashboardLoading: false,
    AnalyticalEmployerRegistrationDashboard: [],
    AnalyticalEmployerRegistrationDashboardLoading: true,
    AnalyticalCandidateRegistrationDashboard: [],
    AnalyticalCandidateRegistrationDashboardLoading: true,

    BillingDashboard: {},
    BillingDashboardLoading: false,
    BillingInvoiceGenerationDashboard: {},
    BillingInvoiceGenerationDashboardLoading: false,

    CompanyPageData: {},
    CompanyPageDataLoading: false,
    CompanySubscriptionDataLoading: false,
    CandidateInterviewsLoading: false,
    CandidateLogsLoading: false,
    ActiveUsers: 0,
    errorMessage: ""


}

const AdminReducers = (state = initalState, action) => {
    switch (action.type) {
        case GET_COMPANY_API_REQUEST:
            return {...state, CompanyDataLoading: true}
        case GET_COMPANY_API_SUCCESS:
            return {...state, Companies: action.payload, CompanyDataLoading: false}
        case GET_COMPANY_API_FAILURE:
            return {...state, CompanyDataLoading: false}
        case GET_USERS_API_REQUEST:
            return {...state, UserDataLoading: true}
        case GET_USERS_API_SUCCESS:
            return {...state, Users: action.payload, UserDataLoading: false}
        case GET_USERS_API_FAILURE:
            return {...state, UserDataLoading: false}
        case SUPPORT_MEMBERS_API_REQUEST:
            return {...state}
        case SUPPORT_MEMBERS_API_SUCCESS:
            return {...state, SupportMembers: action.payload}
        case SUPPORT_MEMBERS_API_FAILURE:
            return {...state}
        case GET_USER_DETAIL_API_REQUEST:
        case GET_EMPLOYER_DETAIL_API_REQUEST:
            return {...state, UserDetailDataLoading: true}
        case GET_USER_DETAIL_API_SUCCESS:
        case GET_EMPLOYER_DETAIL_API_SUCCESS:
            state.userDetail = {...state.userDetail, data: action.payload.data}
            return {...state, UserDetailDataLoading: false}
        case GET_USER_DETAIL_API_FAILURE:
        case GET_EMPLOYER_DETAIL_API_FAILURE:
            return {...state, UserDetailDataLoading: false}
        case GET_PLANS_API_REQUEST:
            return {...state, PlanDataLoading: true}
        case GET_PLANS_API_SUCCESS:
            return {...state, Plans: action.payload, PlanDataLoading: false}
        case GET_PLANS_API_FAILURE:
            return {...state, PlanDataLoading: false}
        case GET_ACTIVE_USERS_API_SUCCESS:
            return {...state, ActiveUsers: action.payload}
        case GET_ROLES_API_REQUEST:
            return {...state, RoleDataLoading: true}
        case GET_ROLES_API_SUCCESS:
            return {...state, Roles: action.payload, RoleDataLoading: false}
        case GET_ROLES_API_FAILURE:
            return {...state, RoleDataLoading: false}
        case GET_ANALYTICAL_DASHBOARD_API_SUCCESS:
            return {...state, AnalyticalDashboard: action.payload, AnalyticalDashboardLoading: false}
        case GET_ANALYTICAL_DASHBOARD_API_FAILURE:
            return {...state, RoleDataLoading: false}
        case GET_BILLING_DASHBOARD_API_SUCCESS:
            return {...state, BillingDashboard: action.payload, BillingDashboardLoading: false}
        case GET_BILLING_DASHBOARD_API_FAILURE:
            return {...state, BillingDashboardLoading: false}
        case GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS:
            return {
                ...state,
                BillingInvoiceGenerationDashboard: action.payload,
                BillingInvoiceGenerationDashboardLoading: false
            }
        case GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE:
            return {...state, BillingInvoiceGenerationDashboardLoading: false}

        case GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS:
            return {
                ...state,
                AnalyticalEmployerRegistrationDashboard: action.payload,
                AnalyticalEmployerRegistrationDashboardLoading: false
            }
        case GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE:
            return {...state, RoleDataLoading: false}
        case GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS:
            return {
                ...state,
                AnalyticalCandidateRegistrationDashboard: action.payload,
                AnalyticalCandidateRegistrationDashboardLoading: false
            }
        case GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE:
            return {...state, RoleDataLoading: false}

        case GET_COMPANY_POSITIONS_API_SUCCESS:
            state.CompanyPageData = {...state.CompanyPageData, positions: action.payload}
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_POSITIONS_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}

        case GET_COMPANY_TEAM_MEMBERS_API_SUCCESS:
            state.CompanyPageData = {...state.CompanyPageData, team_members: action.payload}
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_TEAM_MEMBERS_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}
        case UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS:
            state.CompanyPageData = {
                ...state.CompanyPageData,
                profile: {
                    ...state.CompanyPageData.profile,
                    Company: {...state.CompanyPageData.profile.Company, Subscription: action.payload.Subscription}
                }
            }
            return {...state, CompanySubscriptionDataLoading: false}
        case UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE:
            return {...state, CompanySubscriptionDataLoading: false, errorMessage: action.payload}
        case GET_CANDIDATE_INTERVIEWS_API_SUCCESS:
            state.userDetail = {...state.userDetail, Interviews: action.payload}
            return {...state, CandidateInterviewsLoading: false, errorMessage: action.payload}
        case GET_USER_LOGS_API_SUCCESS:
            state.userDetail = {...state.userDetail, Logs: action.payload}
            return {...state, CandidateLogsLoading: false, errorMessage: action.payload}

        case UPDATE_COMPANY_STATUS_API_SUCCESS:
            // state.CompanyPageData = {
            //     ...state.CompanyPageData,
            //     profile: {
            //         ...state.CompanyPageData.profile,
            //         Company: {...state.CompanyPageData.profile.Company, status: action.payload.status}
            //     }
            // }
            return {...state, CompanyStatusDataLoading: false}
        case UPDATE_COMPANY_STATUS_API_FAILURE:
            return {...state, CompanyStatusDataLoading: false, errorMessage: action.payload}

        case GET_COMPANY_PROFILE_API_SUCCESS:
            state.CompanyPageData = {...state.CompanyPageData, profile: action.payload}
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_PROFILE_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}

        case GET_COMPANY_LOGS_API_SUCCESS:
            state.CompanyPageData = {...state.CompanyPageData, logs: action.payload}
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_LOGS_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}


        case GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS:
            state.CompanyPageData = {...state.CompanyPageData, subscription: action.payload}
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_SUBSCRIPTIONS_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}

        case GET_COMPANY_LIST_API_SUCCESS:
            return {...state, CompaniesList: action.payload, CompanyPageDataLoading: false}
        case GET_COMPANY_LIST_API_FAILURE:
            return {...state, CompanyPageDataLoading: false}
        case GET_COMPANY_LIST_API_REQUEST:
            return {...state, CompanyPageDataLoading: true}

        default:
            return state
    }
}

export default AdminReducers
