import {
    GET_COMPANY_API_REQUEST,
    GET_COMPANY_API_SUCCESS,
    GET_COMPANY_API_FAILURE,
    GET_USERS_API_REQUEST,
    GET_USERS_API_SUCCESS,
    GET_USERS_API_FAILURE,
    GET_USER_DETAIL_API_REQUEST,
    GET_USER_DETAIL_API_SUCCESS,
    GET_USER_DETAIL_API_FAILURE,
    GET_PLANS_API_REQUEST,
    GET_PLANS_API_SUCCESS,
    GET_PLANS_API_FAILURE,
    GET_ROLES_API_REQUEST,
    GET_ROLES_API_SUCCESS,
    GET_ROLES_API_FAILURE,
    GET_ANALYTICAL_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_DASHBOARD_API_FAILURE,
    GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE,
    GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS,
    GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE,
    GET_BILLING_DASHBOARD_API_SUCCESS,
    GET_BILLING_DASHBOARD_API_FAILURE,
    GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS,
    GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE,
    GET_COMPANY_PROFILE_API_SUCCESS,
    GET_COMPANY_PROFILE_API_FAILURE,
    GET_COMPANY_POSITIONS_API_SUCCESS,
    GET_COMPANY_LOGS_API_SUCCESS,
    GET_COMPANY_LOGS_API_FAILURE,
    GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
    GET_COMPANY_SUBSCRIPTIONS_API_FAILURE,
    GET_COMPANY_TEAM_MEMBERS_API_SUCCESS,
    GET_COMPANY_TEAM_MEMBERS_API_FAILURE,
    UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
    UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE,
    UPDATE_COMPANY_STATUS_API_SUCCESS,
    ADD_COMPANY_TEAM_MEMBER_API_SUCCESS,
    ADD_COMPANY_TEAM_MEMBER_API_FAILURE,
    DELETE_COMPANY_TEAM_MEMBER_API_SUCCESS,
    DELETE_COMPANY_TEAM_MEMBER_API_FAILURE,
    ADD_CANDIDATE_INTERVIEW_API_SUCCESS,
    ADD_CANDIDATE_INTERVIEW_API_FAILURE,
    DELETE_COMPANY_POSITION_API_SUCCESS,
    DELETE_COMPANY_POSITION_API_FAILURE,
    GET_CANDIDATE_INTERVIEWS_API_SUCCESS,
    GET_CANDIDATE_INTERVIEWS_API_FAILURE,
    GET_USER_LOGS_API_SUCCESS,
    GET_USER_LOGS_API_FAILURE,
    GET_EMPLOYER_DETAIL_API_REQUEST,
    GET_EMPLOYER_DETAIL_API_SUCCESS,
    GET_EMPLOYER_DETAIL_API_FAILURE,
    USER_DEACTIVATE_API_FAILURE,
    USER_DEACTIVATE_API_REQUEST,
    USER_DEACTIVATE_API_SUCCESS,
    USER_PASSWORD_RESET_API_REQUEST,
    USER_PASSWORD_RESET_API_SUCCESS,
    USER_PASSWORD_RESET_API_FAILURE,
    SUPPORT_MEMBERS_API_REQUEST,
    SUPPORT_MEMBERS_API_SUCCESS,
    SUPPORT_MEMBERS_API_FAILURE,
    ADD_SUPPORT_MEMBERS_API_REQUEST,
    ADD_SUPPORT_MEMBERS_API_SUCCESS,
    ADD_SUPPORT_MEMBERS_API_FAILURE,
    ADD_COMPANY_SUPPORT_MEMBERS_API_REQUEST,
    ADD_COMPANY_SUPPORT_MEMBERS_API_SUCCESS,
    ADD_COMPANY_SUPPORT_MEMBERS_API_FAILURE,
    GET_COMPANY_LIST_API_REQUEST,
    GET_COMPANY_LIST_API_SUCCESS,
    GET_COMPANY_LIST_API_FAILURE,
    DELETE_CANDIDATE_INTERVIEW_API_REQUEST,
    DELETE_CANDIDATE_INTERVIEW_API_SUCCESS,
    DELETE_CANDIDATE_INTERVIEW_API_FAILURE,
    UPDATE_USER_PROFILE_API_REQUEST,
    UPDATE_USER_PROFILE_API_SUCCESS,
    UPDATE_USER_PROFILE_API_FAILURE,
    GET_ACTIVE_USERS_API_REQUEST,
    GET_ACTIVE_USERS_API_SUCCESS,
    GET_ACTIVE_USERS_API_FAILURE, POST_AWS_MEDIA_API_REQUEST, POST_AWS_MEDIA_API_SUCCESS, POST_AWS_MEDIA_API_FAILURE,
} from "../constants/AdminConstants";

import {
    getCompanyTeamMembersService,
    getCompaniesService,
    getUsersService,
    getUserDetailService,
    getPlansService,
    getRolesService,
    getAnalyticalDashboardService,
    getAnalyticalEmployerRegistrationsDashboardService,
    getAnalyticalCandidateRegistrationsDashboardService,
    getBillingDashboardService,
    getBillingInvoiceGenerationDashboardService,
    getCompanyProfileService,
    getCompanyPositionsService,
    getCompanyLogsService,
    getCompanySubscriptionsService,
    updateCompanySubscriptionService,
    updateCompanyStatusService,
    addCompanyMemberService,
    deleteCompanyMemberService,
    deleteCompanyPositionService,
    addCandidateInterviewService,
    getCandidateInterviewsService,
    getUserLogsService,
    getUEmployerDetailService,
    userChangeActiveStatusService,
    userPasswordResetService,
    getSupportMembersService,
    addSupportMemberService,
    createCompanySupportMemberService,
    getCompaniesListService,
    deleteCandidateInterviewService,
    updateUserProfileService,
    getActiveUsersService,
    postMediaToAWSSignedService, postMediaToAWSUploadService, setProfilePdfResumeService
} from "../../services/AdminService";

import {formatError} from "../../services/FormatError";
import {toast} from "react-toastify";

const getCompanies = (postData) => {
    return (dispatch) => {
        dispatch(getCompaniesRequestAction())
        getCompaniesService(postData)
            .then((response) => {
                dispatch(getCompaniesSuccessAction(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompaniesFailedAction(errorMessage))
            })
    }
}

const getUsers = (postData) => {
    return (dispatch) => {
        dispatch(getUsersRequestAction())
        getUsersService(postData)
            .then((response) => {
                dispatch(getUsersSuccessAction(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getUsersFailedAction(errorMessage))
            })
    }
}

export const getActiveUsersAction = (postData) => {
    return (dispatch) => {
        dispatch(getActiveUsersRequestAction())
        getActiveUsersService(postData)
            .then((response) => {
                dispatch(getActiveUsersSuccessAction(response.data.data.active_count[0].count))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getActiveUsersFailureAction(errorMessage))
            })
    }
}

export const getActiveUsersRequestAction = (payload) => {
    return {
        type: GET_ACTIVE_USERS_API_REQUEST,
        payload
    }
}
export const getActiveUsersSuccessAction = (payload) => {
    return {
        type: GET_ACTIVE_USERS_API_SUCCESS,
        payload
    }
}

export const getActiveUsersFailureAction = (payload) => {
    return {
        type: GET_ACTIVE_USERS_API_FAILURE,
        payload
    }
}

const getUserDetailAction = (postData) => {
    return (dispatch) => {
        dispatch(getUserDetailRequestAction())
        getUserDetailService(postData)
            .then((response) => {
                dispatch(getUserDetailSuccessAction(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getUserDetailFailedAction(errorMessage))
            })
    }
}

const getEmployerDetailAction = (postData) => {
    return (dispatch) => {
        dispatch(getEmployerDetailRequestAction())
        getUEmployerDetailService(postData)
            .then((response) => {
                dispatch(getEmployerDetailSuccessAction(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getEmployerDetailFailedAction(errorMessage))
            })
    }
}

const getPlans = () => {
    return (dispatch) => {
        dispatch(getPlansRequestAction())
        getPlansService()
            .then((response) => {
                dispatch(getPlansSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getPlansFailedAction(errorMessage))
            })
    }
}

const getRoles = () => {
    return (dispatch) => {
        dispatch(getRolesRequestAction())
        getRolesService()
            .then((response) => {
                dispatch(getRolesSuccessAction(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getRolesFailedAction(errorMessage))
            })
    }
}

export const getAnalyticalDashboardAction = () => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getAnalyticalDashboardService()
            .then((response) => {
                dispatch(getAnalyticalDashboardSuccessAction(response.data.data))
            })
            .catch((error) => {
                console.log(error);
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getAnalyticalDashboardFailureAction(errorMessage))
            })
    }
}
export const getBillingDashboardAction = () => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getBillingDashboardService()
            .then((response) => {
                dispatch(getBillingDashboardSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getBillingDashboardFailureAction(errorMessage))
            })
    }
}
export const getAnalyticalEmployerRegistrationDashboardAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getAnalyticalEmployerRegistrationsDashboardService(data)
            .then((response) => {
                dispatch(getAnalyticalEmployerRegistrationDashboardSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getAnalyticalEmployerRegistrationDashboardFailureAction(errorMessage))
            })
    }
}
export const getAnalyticalCandidateRegistrationDashboardAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getAnalyticalCandidateRegistrationsDashboardService(data)
            .then((response) => {
                dispatch(getAnalyticalCandidateRegistrationDashboardSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getAnalyticalCandidateRegistrationDashboardFailureAction(errorMessage))
            })
    }
}
export const getBillingInvoiceGenerationDashboardAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getBillingInvoiceGenerationDashboardService(data)
            .then((response) => {
                dispatch(getBillingInvoiceGenerationDashboardSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getBillingInvoiceGenerationDashboardFailureAction(errorMessage))
            })
    }
}

export const getCompanyProfileAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCompanyProfileService(data)
            .then((response) => {
                dispatch(getCompanyProfileSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompanyProfileFailureAction(errorMessage))
            })
    }
}

export const getCompanyTeamMembersAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCompanyTeamMembersService(data)
            .then((response) => {
                dispatch(getCompanyTeamMembersSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompanyTeamMembersFailureAction(errorMessage))
            })
    }
}

export const getCompanyPositionsAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCompanyPositionsService(data)
            .then((response) => {
                dispatch(getCompanyPositionsSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompanyPositionsFailureAction(errorMessage))
            })
    }
}


export const getCompanyLogsAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCompanyLogsService(data)
            .then((response) => {
                dispatch(getCompanyLogsSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompanyLogsFailureAction(errorMessage))
            })
    }
}


export const getCompanySubscriptionsAction = (data) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCompanySubscriptionsService(data)
            .then((response) => {
                dispatch(getCompanySubscriptionsSuccessAction(response.data.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompanySubscriptionsFailureAction(errorMessage))
            })
    }
}


export const updateCompanySubscriptionAction = (data, cb) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        updateCompanySubscriptionService(data)
            .then((response) => {
                dispatch(updateCompanySubscriptionSuccessAction(response.data.data))
                toast.success("Company Subscription Updated", {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(updateCompanySubscriptionFailureAction(errorMessage))
            })
    }
}

export const updateCompanyStatusAction = (data, cb = null) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        updateCompanyStatusService(data)
            .then((response) => {
                console.log(response.data.Company)
                dispatch(updateCompanyStatusSuccessAction(response.data.Company))

                toast.success("Company Status Updated", {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(updateCompanyStatusFailureAction(errorMessage))
            })
    }
}

export const deleteCompanyMemberAction = (data, cb) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        deleteCompanyMemberService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(deleteCompanyMemberSuccessAction(response.data.data))
                cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(deleteCompanyMemberFailureAction(errorMessage))
            })
    }
}
export const addCandidateInterviewAction = (data, cb) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        addCandidateInterviewService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(addCandidateInterviewSuccessAction(response.data.data))
                toast.success("Interview Added", {autoClose: 2000})
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(addCandidateInterviewFailureAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export const addCandidateInterviewSuccessAction = (payload) => {
    return {
        type: ADD_CANDIDATE_INTERVIEW_API_SUCCESS,
        payload
    }
}

export const addCandidateInterviewFailureAction = (payload) => {
    return {
        type: ADD_CANDIDATE_INTERVIEW_API_FAILURE,
        payload
    }
}

export const deleteCandidateInterviewAction = (data, cb) => {
    return (dispatch) => {
        dispatch(deleteCandidateInterviewRequestAction())
        deleteCandidateInterviewService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(deleteCandidateInterviewSuccessAction(response.data.data))

                toast.success("Interview deleted", {autoClose: 2000})
                cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(deleteCandidateInterviewFailureAction(errorMessage))
            })
    }
}

export const deleteCandidateInterviewRequestAction = (payload) => {
    return {
        type: DELETE_CANDIDATE_INTERVIEW_API_REQUEST,
        payload
    }
}
export const deleteCandidateInterviewSuccessAction = (payload) => {
    return {
        type: DELETE_CANDIDATE_INTERVIEW_API_SUCCESS,
        payload
    }
}

export const deleteCandidateInterviewFailureAction = (payload) => {
    return {
        type: DELETE_CANDIDATE_INTERVIEW_API_FAILURE,
        payload
    }
}

export const getCandidateInterviewsAction = (data, cb = null) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getCandidateInterviewsService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(getCandidateInterviewsSuccessAction(response.data.data))
                if (cb) cb();
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCandidateInterviewsFailureAction(errorMessage))
                if (cb) cb();
            })
    }
}

export const getCandidateInterviewsSuccessAction = (payload) => {
    return {
        type: GET_CANDIDATE_INTERVIEWS_API_SUCCESS,
        payload
    }
}

export const getCandidateInterviewsFailureAction = (payload) => {
    return {
        type: GET_CANDIDATE_INTERVIEWS_API_FAILURE,
        payload
    }
}

export const getUserLogsAction = (data, cb = null) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        getUserLogsService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(getUserLogsSuccessAction(response.data.data))
                if (cb) cb();
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getUserLogsFailureAction(errorMessage))
                if (cb) cb();
            })
    }
}

export const getUserLogsSuccessAction = (payload) => {
    return {
        type: GET_USER_LOGS_API_SUCCESS,
        payload
    }
}

export const getUserLogsFailureAction = (payload) => {
    return {
        type: GET_USER_LOGS_API_FAILURE,
        payload
    }
}

export const updateUserProfileAction = (data, cb) => {
    return (dispatch) => {
        dispatch(updateUserProfileRequestAction())
        updateUserProfileService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(updateUserProfileSuccessAction(response.data.data))
                toast.success("Profile updated", {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(updateUserProfileFailureAction(errorMessage))
            })
    }
}

export const updateUserProfileRequestAction = (payload) => {
    return {
        type: UPDATE_USER_PROFILE_API_REQUEST,
        payload
    }
}
export const updateUserProfileSuccessAction = (payload) => {
    return {
        type: UPDATE_USER_PROFILE_API_SUCCESS,
        payload
    }
}

export const updateUserProfileFailureAction = (payload) => {
    return {
        type: UPDATE_USER_PROFILE_API_FAILURE,
        payload
    }
}
export const userChangeActiveStatusAction = (data, cb = null) => {
    return (dispatch) => {
        dispatch(userDeactivateRequestAction())
        userChangeActiveStatusService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(userDeactivateSuccessAction(response.data.message))
                toast.success(response.data.message, {autoClose: 2000})
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(userDeactivateFailureAction(errorMessage))
                if (cb) cb(false)
            })
    }
}
export const userDeactivateRequestAction = (payload) => {
    return {
        type: USER_DEACTIVATE_API_REQUEST,
        payload
    }
}

export const userDeactivateSuccessAction = (payload) => {
    return {
        type: USER_DEACTIVATE_API_SUCCESS,
        payload
    }
}

export const userDeactivateFailureAction = (payload) => {
    return {
        type: USER_DEACTIVATE_API_FAILURE,
        payload
    }
}

export const userPasswordResetAction = (data, cb = null) => {
    return (dispatch) => {
        dispatch(userPasswordResetRequestAction())
        userPasswordResetService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(userPasswordResetSuccessAction(response.data.message))
                toast.success(response.data.message, {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(userPasswordResetFailureAction(errorMessage))
            })
    }
}


export const userPasswordResetRequestAction = (payload) => {
    return {
        type: USER_PASSWORD_RESET_API_REQUEST,
        payload
    }
}

export const userPasswordResetSuccessAction = (payload) => {
    return {
        type: USER_PASSWORD_RESET_API_SUCCESS,
        payload
    }
}

export const userPasswordResetFailureAction = (payload) => {
    return {
        type: USER_PASSWORD_RESET_API_FAILURE,
        payload
    }
}


// Support

export const supportMembersAction = (data, cb = null) => {
    return (dispatch) => {
        dispatch(supportMemberRequestAction())
        getSupportMembersService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(supportMemberSuccessAction(response.data.data))
                // toast.success(response.data.message, {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(supportMemberFailureAction(errorMessage))
            })
    }
}


export const supportMemberRequestAction = (payload) => {
    return {
        type: SUPPORT_MEMBERS_API_REQUEST,
        payload
    }
}

export const supportMemberSuccessAction = (payload) => {
    return {
        type: SUPPORT_MEMBERS_API_SUCCESS,
        payload
    }
}

export const supportMemberFailureAction = (payload) => {
    return {
        type: SUPPORT_MEMBERS_API_FAILURE,
        payload
    }
}

export const addSupportMembersAction = (data, history, cb = null) => {
    return (dispatch) => {
        dispatch(addSupportMemberRequestAction())
        addSupportMemberService(data)
            .then((response) => {
                dispatch(addSupportMemberSuccessAction(response.data.User));
                toast.success("Support user created", {autoClose: 2000});
                if (cb) cb(response.data.User.id);
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(addSupportMemberFailureAction(errorMessage))
                if (cb) cb(false);
            })
    }
}


export const addSupportMemberRequestAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_REQUEST,
        payload
    }
}

export const addSupportMemberSuccessAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_SUCCESS,
        payload
    }
}

export const addSupportMemberFailureAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_FAILURE,
        payload
    }
}


export const addCompanySupportMembersAction = (data, history, cb = null) => {
    return (dispatch) => {
        dispatch(addCompanySupportMemberRequestAction())
        createCompanySupportMemberService(data)
            .then((response) => {
                dispatch(addCompanySupportMemberSuccessAction(response.data.data))
                toast.success(response.data.message, {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(addCompanySupportMemberFailureAction(errorMessage))
                if (cb) cb()
            })
    }
}


export const addCompanySupportMemberRequestAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_REQUEST,
        payload
    }
}

export const addCompanySupportMemberSuccessAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_SUCCESS,
        payload
    }
}

export const addCompanySupportMemberFailureAction = (payload) => {
    return {
        type: ADD_COMPANY_SUPPORT_MEMBERS_API_FAILURE,
        payload
    }
}


export const getCompaniesListAction = (data, cb = null) => {
    return (dispatch) => {
        dispatch(getCompaniesListRequestAction())
        getCompaniesListService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(getCompaniesListSuccessAction(response.data.data))
                // toast.success(response.data.message, {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(getCompaniesListFailedAction(errorMessage))
            })
    }
}


export const getCompaniesListRequestAction = (payload) => {
    return {
        type: GET_COMPANY_LIST_API_REQUEST,
        payload
    }
}

export const getCompaniesListSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_LIST_API_SUCCESS,
        payload
    }
}

export const getCompaniesListFailedAction = (payload) => {
    return {
        type: GET_COMPANY_LIST_API_FAILURE,
        payload
    }
}


export const deleteCompanyPositionAction = (data, cb) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        deleteCompanyPositionService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(deleteCompanyPositionSuccessAction(response.data.data))
                toast.success("Position deleted successfully", {autoClose: 2000})
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(deleteCompanyPositionFailureAction(errorMessage))
            })
    }
}


export const deleteCompanyPositionSuccessAction = (payload) => {
    return {
        type: DELETE_COMPANY_POSITION_API_SUCCESS,
        payload
    }
}

export const deleteCompanyPositionFailureAction = (payload) => {
    return {
        type: DELETE_COMPANY_POSITION_API_FAILURE,
        payload
    }
}


export const deleteCompanyMemberSuccessAction = (payload) => {
    return {
        type: DELETE_COMPANY_TEAM_MEMBER_API_SUCCESS,
        payload
    }
}

export const deleteCompanyMemberFailureAction = (payload) => {
    return {
        type: DELETE_COMPANY_TEAM_MEMBER_API_FAILURE,
        payload
    }
}

export const addCompanyMemberAction = (data, cb) => {
    return (dispatch) => {
        // dispatch(getRolesRequestAction())
        addCompanyMemberService(data)
            .then((response) => {
                console.log(response.data.data)
                dispatch(addCompanyMemberSuccessAction(response.data.data))
                const d = {
                    "pagination": {
                        "page": 1,
                        "size": 10
                    },
                    company_id: data.company_id
                }
                dispatch(getCompanyTeamMembersAction(d))
                cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage, {autoClose: 2000})
                dispatch(addCompanyMemberFailureAction(errorMessage))
            })
    }
}
export const addCompanyMemberSuccessAction = (payload) => {
    return {
        type: ADD_COMPANY_TEAM_MEMBER_API_SUCCESS,
        payload
    }
}

export const addCompanyMemberFailureAction = (payload) => {
    return {
        type: ADD_COMPANY_TEAM_MEMBER_API_FAILURE,
        payload
    }
}
export const updateCompanyStatusSuccessAction = (payload) => {
    return {
        type: UPDATE_COMPANY_STATUS_API_SUCCESS,
        payload
    }
}

export const updateCompanyStatusFailureAction = (payload) => {
    return {
        type: UPDATE_COMPANY_STATUS_API_SUCCESS,
        payload
    }
}

export const updateCompanySubscriptionSuccessAction = (payload) => {
    return {
        type: UPDATE_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
        payload
    }
}

export const updateCompanySubscriptionFailureAction = (payload) => {
    return {
        type: UPDATE_COMPANY_SUBSCRIPTIONS_API_FAILURE,
        payload
    }
}


export const getCompanyProfileSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_PROFILE_API_SUCCESS,
        payload
    }
}

export const getCompanyProfileFailureAction = (payload) => {
    return {
        type: GET_COMPANY_PROFILE_API_FAILURE,
        payload
    }
}

export const getCompanyTeamMembersSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_TEAM_MEMBERS_API_SUCCESS,
        payload
    }
}

export const getCompanyTeamMembersFailureAction = (payload) => {
    return {
        type: GET_COMPANY_TEAM_MEMBERS_API_FAILURE,
        payload
    }
}
export const getCompanyPositionsSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_POSITIONS_API_SUCCESS,
        payload
    }
}

export const getCompanyPositionsFailureAction = (payload) => {
    return {
        type: GET_COMPANY_POSITIONS_API_SUCCESS,
        payload
    }
}
export const getCompanyLogsSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_LOGS_API_SUCCESS,
        payload
    }
}

export const getCompanyLogsFailureAction = (payload) => {
    return {
        type: GET_COMPANY_LOGS_API_FAILURE,
        payload
    }
}


export const getCompanySubscriptionsSuccessAction = (payload) => {
    return {
        type: GET_COMPANY_SUBSCRIPTIONS_API_SUCCESS,
        payload
    }
}

export const getCompanySubscriptionsFailureAction = (payload) => {
    return {
        type: GET_COMPANY_SUBSCRIPTIONS_API_FAILURE,
        payload
    }
}


export const getAnalyticalDashboardSuccessAction = (payload) => {
    return {
        type: GET_ANALYTICAL_DASHBOARD_API_SUCCESS,
        payload
    }
}

export const getAnalyticalDashboardFailureAction = (payload) => {
    return {
        type: GET_ANALYTICAL_DASHBOARD_API_FAILURE,
        payload
    }
}

export const getBillingDashboardSuccessAction = (payload) => {
    return {
        type: GET_BILLING_DASHBOARD_API_SUCCESS,
        payload
    }
}

export const getBillingDashboardFailureAction = (payload) => {
    return {
        type: GET_BILLING_DASHBOARD_API_FAILURE,
        payload
    }
}
export const getBillingInvoiceGenerationDashboardSuccessAction = (payload) => {
    return {
        type: GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_SUCCESS,
        payload
    }
}

export const getBillingInvoiceGenerationDashboardFailureAction = (payload) => {
    return {
        type: GET_BILLING_INVOICE_GENERATION_DASHBOARD_API_FAILURE,
        payload
    }
}
export const getAnalyticalEmployerRegistrationDashboardSuccessAction = (payload) => {
    return {
        type: GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_SUCCESS,
        payload
    }
}

export const getAnalyticalEmployerRegistrationDashboardFailureAction = (payload) => {
    return {
        type: GET_ANALYTICAL_EMPLOYER_REGISTRATION_DASHBOARD_API_FAILURE,
        payload
    }
}
export const getAnalyticalCandidateRegistrationDashboardSuccessAction = (payload) => {
    return {
        type: GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_SUCCESS,
        payload
    }
}

export const getAnalyticalCandidateRegistrationDashboardFailureAction = (payload) => {
    return {
        type: GET_ANALYTICAL_CANDIDATE_REGISTRATION_DASHBOARD_API_FAILURE,
        payload
    }
}

export const getCompaniesRequestAction = () => {
    return {
        type: GET_COMPANY_API_REQUEST,
    }
}

export const getCompaniesSuccessAction = (data) => {
    return {
        type: GET_COMPANY_API_SUCCESS,
        payload: data,
    }
}

export const getCompaniesFailedAction = (data) => {
    return {
        type: GET_COMPANY_API_FAILURE,
        payload: data,
    }
}

export const getUsersRequestAction = () => {
    return {
        type: GET_USERS_API_REQUEST,
    }
}

export const getUsersSuccessAction = (data) => {
    return {
        type: GET_USERS_API_SUCCESS,
        payload: data,
    }
}

export const getUsersFailedAction = (data) => {
    return {
        type: GET_USERS_API_FAILURE,
        payload: data,
    }
}

export const getUserDetailRequestAction = () => {
    return {
        type: GET_USER_DETAIL_API_REQUEST,
    }
}

export const getUserDetailSuccessAction = (data) => {
    return {
        type: GET_USER_DETAIL_API_SUCCESS,
        payload: data,
    }
}

export const getUserDetailFailedAction = (data) => {
    return {
        type: GET_USER_DETAIL_API_FAILURE,
        payload: data,
    }
}
export const getEmployerDetailRequestAction = () => {
    return {
        type: GET_EMPLOYER_DETAIL_API_REQUEST,
    }
}

export const getEmployerDetailSuccessAction = (data) => {
    return {
        type: GET_EMPLOYER_DETAIL_API_SUCCESS,
        payload: data,
    }
}

export const getEmployerDetailFailedAction = (data) => {
    return {
        type: GET_EMPLOYER_DETAIL_API_FAILURE,
        payload: data,
    }
}
export const getPlansRequestAction = () => {
    return {
        type: GET_PLANS_API_REQUEST,
    }
}

export const getPlansSuccessAction = (data) => {
    return {
        type: GET_PLANS_API_SUCCESS,
        payload: data,
    }
}

export const getPlansFailedAction = (data) => {
    return {
        type: GET_PLANS_API_FAILURE,
        payload: data,
    }
}
export const getRolesRequestAction = () => {
    return {
        type: GET_ROLES_API_REQUEST,
    }
}

export const getRolesSuccessAction = (data) => {
    return {
        type: GET_ROLES_API_SUCCESS,
        payload: data,
    }
}

export const getRolesFailedAction = (data) => {
    return {
        type: GET_ROLES_API_FAILURE,
        payload: data,
    }
}


const postMediaToAWS = (data, callback, cb = null) => {
    return (dispatch) => {
        dispatch(postMediaToAWSRequestAction())
        postMediaToAWSSignedService(data.key)
            .then((response) => {
                if (response.data.data) {
                    postMediaToAWSUploadService(response.data.data, data.file)
                        .then((response) => {
                            callback(response, data.key)
                            if (response) {
                                dispatch(postMediaToAWSSuccessAction(response))
                                // if (data.key.slice(data.key.lastIndexOf(".") + 1) === "webm" && data.VR) {
                                //     dispatch(setProfileVideoResume(data.key))
                                // } else
                                if (data.key.slice(data.key.lastIndexOf(".") + 1) === "pdf") {
                                    setProfilePdfResumeService(data).then(res => {
                                        toast.success("Candidate resume uploaded", {autoClose: 2000})
                                    }).catch(e => {
                                        toast.error(e?.error, {autoClose: 2000})
                                    })
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.response) {
                                callback(error.response, data.key)
                                dispatch(postMediaToAWSFailedAction(error.response))
                                if (cb) {
                                    cb({
                                        alert: true,
                                        type: 'error',
                                        variant: 'danger',
                                        msg: error.response.statusText
                                    })
                                } else {
                                    toast.error(error.response.statusText, {autoClose: 2000})
                                }
                            } else {
                                callback(error, data.key)
                                dispatch(postMediaToAWSFailedAction(error.message))
                                if (cb) {
                                    cb({
                                        alert: true,
                                        type: 'error',
                                        variant: 'danger',
                                        msg: error.message
                                    })
                                } else {
                                    toast.error(error.message, {autoClose: 2000})
                                }
                            }
                        })
                }
            })
            .catch((error) => {
                if (error.response) {
                    callback(error.response, data.key)
                    dispatch(postMediaToAWSFailedAction(error.response))
                    toast.error(error.response.statusText, {autoClose: 2000})
                } else {
                    callback(error, data.key)
                    dispatch(postMediaToAWSFailedAction(error.message))
                    if (cb) {
                        cb({
                            alert: true,
                            type: 'error',
                            variant: 'danger',
                            msg: error.message
                        })
                    } else {
                        toast.error(error.message, {autoClose: 2000})
                    }
                }
            })
    }
}

export const postMediaToAWSRequestAction = () => {
    return {
        type: POST_AWS_MEDIA_API_REQUEST,
    }
}

export const postMediaToAWSSuccessAction = (data) => {
    return {
        type: POST_AWS_MEDIA_API_SUCCESS,
        payload: data,
    }
}

export const postMediaToAWSFailedAction = (data) => {
    return {
        type: POST_AWS_MEDIA_API_FAILURE,
        payload: data,
    }
}

export {getCompanies, getUsers, getUserDetailAction, getPlans, getRoles, getEmployerDetailAction, postMediaToAWS}
