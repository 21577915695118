import React, {useEffect, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    ModalFooter,
    Input,
    Form,
    FormGroup,
    Label,
} from "reactstrap"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {Modal} from "react-bootstrap"
import avatar from "../../../images/default.svg"
import Switch from "../common/Switch"
import ImageCropModal from "../common/ImageCropModal"
import Loader from "../common/Loader/Loader"
import {updateUserProfileAction} from "../../../redux/actions/AdminActions";

const EditUserProfile = ({user, updateUserProfileAction, getUserDetail, toggle}) => {
    let errorsObj = {fname: "", lname: "", nEmail: "", nPhone: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [fname, setFName] = useState("")
    const [lname, setLName] = useState("")
    const [nEmail, setEmail] = useState("")
    const [nPhone, setPhone] = useState("")
    const [img, setImg] = useState(undefined)
    const [uploadFile, setUploadFile] = useState(undefined)
    const [preImg, setPreImg] = useState(undefined)
    const [cropModal, setCropModal] = useState(false)
    const hiddenFileInput = React.useRef(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        setFName(user?.first_name);
        setLName(user?.last_name);
        setEmail(user?.email);
        setPhone(user?.phone);
        setImg(user?.image);
        setPreImg(user?.image)


    }, [user])

    const handleChange = (event) => {
        if (event.target.files[0]) {
            const fileUploaded = event.target.files[0]

            setImg(URL.createObjectURL(fileUploaded))
            setTimeout(() => {

                setCropModal(true)
            }, 300)
        }
    }

    const handleCropSubmit = (file) => {
        const media_key = "user/" + file.lastModified + "." + file.type.split("/")[1]

        setImg(media_key)
        setUploadFile(file)
        setPreImg(URL.createObjectURL(file))

        setCropModal(false)
    }

    function onSubmit(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (fname === "") {
            errorObj.fname = "First Name is Required"
            error = true
        }
        if (lname === "") {
            errorObj.lname = "Last Name is Required"
            error = true
        }
        if (nEmail === "") {
            errorObj.nEmail = "Email is Required"
            error = true
        }
        if (nPhone === "") {
            errorObj.nPhone = "Phone Number is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            user_data: {
                first_name: fname,
                last_name: lname,
                // email: nEmail,
                phone: nPhone,
                // image: img,
            },
            user_id: user.id
        }
        
        updateUserProfileAction(data, () => {
            setIsLoading(false)
            getUserDetail({user_id: user.id})
            toggle()
        })

        // props.uploadMediaToAWS({ media_key: img, file: uploadFile }, props, () => {
        //   props.updateUserProfile(data, props.history, () => {
        //     props.showAlert()
        //     setImg(undefined)
        //     setIsLoading(false)
        //   })
        // })
    }

    return (
        <>
            <Row>
                {/* <Col lg={6}> */}
                {/* <Card> */}
                <CardBody>
                    <form onSubmit={onSubmit} className=" col-12 ">
                        <div className="row align-items-center">
                            <div className={"col-2"} style={{maxWidth: "fit-content"}}>
                                <img src={preImg || avatar} className="rounded-circle avatar-mb" width={"130"}/>
                            </div>
                            <div className={"col-8"}>
                                <h3>User Profile Picture</h3>
                                {/*<small className="d-block">Maximum Size Allowed 2MB | JPEG, JPG or PNG</small>*/}
                                {/*<div className="custom-file my-2">*/}
                                {/*  <input*/}
                                {/*    type="file"*/}
                                {/*    className="custom-file-input"*/}
                                {/*    accept="image/*"*/}
                                {/*    ref={hiddenFileInput}*/}
                                {/*    onChange={(e) => handleChange(e)}*/}
                                {/*  />*/}
                                {/*  <label className="btn btn-primary">{"Browse"}</label>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        First Name{" "}
                                        <span className="text-muted">
                          <small>Required</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-fname1"
                                        placeholder="Enter a first name.."
                                        onChange={(e) => setFName(e.target.value)}
                                        value={fname}
                                    />
                                    {errors.fname &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.fname}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Last Name{" "}
                                        <span className="text-muted">
                          <small>Required</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-lname1"
                                        placeholder="Enter a last name.."
                                        onChange={(e) => setLName(e.target.value)}
                                        value={lname}
                                    />
                                    {errors.lname &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.lname}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Email{" "}
                                        <span className="text-muted">
                          <small>Required</small>
                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="val-email1"
                                        placeholder="Enter a Email Address"
                                        // onChange={(e) => setEmail(e.target.value)}
                                        value={nEmail}
                                        disabled
                                    />
                                    {errors.nEmail &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.nEmail}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className={`form-group`}>
                                    <label className="text-label">
                                        Phone Number{" "}
                                        <span className="text-muted">
                          <small>Required</small>
                        </span>
                                    </label>
                                    <PhoneInput
                                        country={"us"}
                                        value={nPhone}
                                        onChange={(phone) => setPhone(phone)}
                                        placeholder="541-754-3010"
                                        inputStyle={{width: "99%", height: "-webkit-fill-available", border: "hidden"}}
                                        containerClass={"form-control"}
                                        containerStyle={{padding: 0}}
                                        buttonStyle={{borderRadius: " 10px 0 0 10px", border: "0"}}
                                    />
                                    {errors.nPhone &&
                                        <div className="animated fadeInUp text-danger fs-12">{errors.nPhone}</div>}
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn mr-2 btn-primary btn-block col-4"
                            // disabled={isSubmitting}
                            onClick={() => setIsLoading(false)}
                        >
                            {isLoading ? <Loader spinnerClass="text-white"/> : "Save changes"}
                        </button>
                    </form>
                    {/* <div className="d-flex flex-column justify-content-center align-items-center">
                    <div>
                      <h4>Basic Information</h4>
                      <hr />
                    </div>
                    <div>
                      <img src={avatar} alt="" />
                      <h5 className="mt-4">3MP Atlanta</h5>
                      <span className="d-block mb-1">
                        <i className="bx bx-phone-call"></i>&nbsp;923145144956
                      </span>
                      <span className="d-block mb-1">
                        <i className="bx bx-mail-send"></i>&nbsp;admin@3mp.com
                      </span>
                      <span className="d-block mb-1">
                        <i className="mdi mdi-calendar-blank green mr-2"></i>2021-02-24
                      </span>
                    </div>
                  </div> */}
                </CardBody>
                {/* </Card> */}
                {/* </Col> */}
            </Row>

            <Modal
                centered={true}
                size="lg"
                show={cropModal}
                onHide={() => {
                    setCropModal(!cropModal)
                    setImg(undefined)
                }}
            >
                <ImageCropModal
                    handleCropSubmit={handleCropSubmit}
                    img={img}
                    toggle={() => {
                        setCropModal(!cropModal)
                        setImg(undefined)
                    }}
                />
            </Modal>
        </>
    )
}

export default EditUserProfile
