import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Formik } from "formik";
import * as Yup from "yup";


const loginSchema = Yup.object().shape({
  fname: Yup.string()
    .min(3, "Your first name must consist of at least 3 characters ")
    .required("Firstname is required"),
  lname: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .required("Lastname is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .min(6, "Password is too short - should be 6 chars minimum")
    .required("Password is required"),
  // phone: Yup.string()
  //   .min(5, "Your password must be at least 5 characters long")
  //   .max(50, "Your password must be at least 5 characters long")
  //   .required("Phone is required"),
  cname: Yup.string()
    .required("Company is required"),
});

const StepOne = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState();

  return (
    <section>
        
        <Formik
          initialValues={{ fname: "", lname: "", email: "", password: "", phone: "+1", cname: "" }}
          // validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting }) => {
            values.phone=phone;
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.fname
                        ? errors.fname
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        First Name{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    {/* <div className="input-group"> */}
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user" />{" "}
                        </span>
                      </div> */}
                      <input
                        type="text"
                        className="form-control"
                        id="val-fname1"
                        placeholder="Enter a first name.."
                        name="fname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fname}
                      />
                      <div
                        id="val-fname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.fname && errors.fname}
                      </div>

                      <div
                        id="val-fname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      />
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.lname
                        ? errors.lname
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        Last Name{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    {/* <div className="input-group"> */}
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user" />{" "}
                        </span>
                      </div> */}
                      <input
                        type="text"
                        className="form-control"
                        id="val-lname1"
                        placeholder="Enter a last name.."
                        name="lname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lname}
                      />
                      <div
                        id="val-lname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.lname && errors.lname}
                      </div>

                      <div
                        id="val-lname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      />
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.email
                        ? errors.email
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        Email{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    {/* <div className="input-group"> */}
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user" />{" "}
                        </span>
                      </div> */}
                      <input
                        type="text"
                        className="form-control"
                        id="val-email1"
                        placeholder="Enter a Email Address"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div
                        id="val-email1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.email && errors.email}
                      </div>

                      <div
                        id="val-email1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      />
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.password
                        ? errors.password
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        Password{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    <div className="input-group transparent-appen">
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          {" "}
                          <i className="fa fa-lock" />{" "}
                        </span>
                      </div> */}
                      <input
                        type={`${showPassword ? "text" : "password"}`}
                        className="form-control"
                        id="val-password1"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Choose a safe one.."
                      />

                      <div
                        className="input-group-append show-pass"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <span className="input-group-text">
                          {" "}
                          <i className="fa fa-eye-slash" />
                        </span>
                      </div>
                      <div
                        id="val-password1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.password && errors.password}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.phone
                        ? errors.phone
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        Phone Number{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    {/* <div className="input-group"> */}
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user" />{" "}
                        </span>
                      </div> */}
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        id="val-phone1"
                        onChange={phone => setPhone(phone)}
                        onBlur={handleBlur}
                        placeholder="541-754-3010"
                        inputStyle={{width: '100%', height: '-webkit-fill-available', border: 'hidden',}}
                        containerClass={"form-control"}
                        containerStyle={{padding:0,border: '3px solid #EEEEEE'}}
                        buttonStyle={{borderRadius:' 10px 0 0 10px', border:'0'}}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        id="val-phone1"
                        placeholder="Enter a Phone Number.."
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      /> */}
                      <div
                        id="val-phone1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.phone && errors.phone}
                      </div>

                      <div
                        id="val-phone1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      />
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div
                    className={`form-group ${
                      values.cname
                        ? errors.cname
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                  >
                    <label className="text-label">
                        Company Name{" "}
                      <span className="text-muted">
                        <small>Required</small>
                      </span>
                    </label>
                    <div className="input-group">
                      {/* <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-user" />{" "}
                        </span>
                      </div> */}
                      <input
                        type="text"
                        className="form-control"
                        id="val-cname1"
                        placeholder="Enter a company name.."
                        name="cname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cname}
                      />
                      <div
                        id="val-cname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.cname && errors.cname}
                      </div>

                      <div
                        id="val-cname1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
{/* 
              <button
                type="submit"
                className="btn mr-2 btn-primary"
                disabled={isSubmitting}
              >
                Submit
              </button>
              <button type="submit" className="btn btn-light">
                cencel
              </button> */}
            </form>
          )}
        </Formik>
    </section>
  );
};

export default StepOne;
