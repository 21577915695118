import axios from "axios"
import store from "../redux/store/Store"

const axiosInstance = axios.create({
    // baseURL: `http://localhost:4000`
    baseURL: `https://digitalhire.iqsoft.io`,
})

axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState()
        const token = localStorage.getItem("adminData")
            ? JSON.parse(localStorage.getItem("adminData")).token
            : state.Auth.AuthData.token
        config.params = config.params || {}
        config.headers = {
            Authorization: `Bearer ${token}`,
        }
        return config
    },
    (error) => {
        if (401 === error.response.status) {
            localStorage.removeItem("adminData")
        } else {
            return Promise.reject(error)
        }
    }
)

export default axiosInstance
