import React, { useContext, useEffect } from "react"
import { Redirect } from "react-router-dom"

import { Switch, Route } from "react-router-dom"
import { useLocation } from "react-router";

import Nav from "./layouts/nav"
import Footer from "./layouts/Footer"
/// Css
import "./index.css"
import "./chart.css"
import "./step.css"

/// Dashboard
import Home from "./components/Dashboard/Home"
import Home2 from "./components/Dashboard/Billing"
import companies from "./components/companies/index"
import CompanyDetails from "./components/companies/CompanyDetails"
import Users from "./components/Users/index"
import Support from "./components/Support/index"
import UserDetails from "./components/Users/UserDetails"
import EmployerDetails from "./components/Users/EmployerDetails"
/// Pages
import Registration from "./pages/Registration"
import Login from "./pages/Login"
import ForgotPassword from "./pages/ForgotPassword"
import LockScreen from "./pages/LockScreen"
import Error400 from "./pages/Error400"
import Error403 from "./pages/Error403"
import Error404 from "./pages/Error404"
import Error500 from "./pages/Error500"
import Error503 from "./pages/Error503"
import { ThemeContext } from "../context/ThemeContext"

const Markup = (props) => {
  const { permissions } = props
  const { menuToggle } = useContext(ThemeContext)
  
  const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "analytics", component: Home },
    { url: "billing", component: Home2 },
    { url: "companies", component: companies },
    { url: "company-detail/:id", component: CompanyDetails },
    { url: "users", component: Users },
    { url: "support", component: Support },
    { url: "user-detail/:id", component: UserDetails },
    { url: "employer-detail/:id", component: EmployerDetails },

    /// pages
    { url: "auth-login", component: Login },
    { url: "auth-register", component: Registration },
    { url: "auth-lock-screen", component: LockScreen },
    { url: "auth-forgot-password", component: ForgotPassword },
    { url: "auth-error-400", component: Error400 },
    { url: "auth-error-403", component: Error403 },
    { url: "auth-error-404", component: Error404 },
    { url: "auth-error-500", component: Error500 },
    { url: "auth-error-503", component: Error503 },
    // { url: "*", component: () => <Redirect to="analytics" /> },
    // { url: "*", component: () => <Redirect to="auth-login" /> },
  ]
  
  let path = window.location.pathname
  path = path.split("/")
  path = path[path.length - 1]

  let pagePath = path.split("-").includes("page")
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""}`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`} style={{ minHeight: window.screen.height - 60 }}>
            <Switch>
              {routes.map((data, i) => (
                <Route key={i} exact path={`/${data.url}`} component={data.component} />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </>
  )
}

export default Markup
