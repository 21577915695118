import React, {useEffect, useState} from "react"
import {
    Card,
    CardBody,
    Table,
} from "reactstrap"
import PositionFilter from "./PositionFilter";
import Pagination from "../common/Pagination/Pagination";
import pic1 from "../../../images/default.svg"
import {
    deleteCompanyPositionAction,
    getCompanyPositionsAction,
    getCompanyTeamMembersAction
} from "../../../redux/actions/AdminActions";
import {getCompanyPositionsSelector, getCompanyTeamMembersSelector} from "../../../redux/selectors/AdminSelector";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";

const Position = ({
                      modalShow,
                      setModalShow,
                      companyId,
                      loadCompanyPositionsData,
                      companyPositionsData,
                      deleteCompanyPosition
                  }) => {
    const [query, setQuery] = useState({});
    const [sort, setSort] = useState(["createdAt", "ASC"]);
    const [trackSort, setTrackSort] = useState(0);


    useEffect(() => {
        if (companyId) onSubmit();
    }, [companyId, query, sort])

    const onSubmit = (page = 1) => {
        const data = {
            "company_id": companyId,
            "filter_options": {
                ...query,
                "sort_array": [sort]
            },
            "pagination": {
                "page": page,
                "size": 10
            }
        }

        loadCompanyPositionsData(data)
    }

    return (
        <>
            <PositionFilter query={query} setQuery={setQuery}/>
            <Card className="mb-2">
                <CardBody>
                    <div>
                        <div>
                            <h4>Positions</h4>
                        </div>
                        <hr/>
                    </div>
                    <Table responsive className="custom-table text-dark">
                        <thead>
                        <tr>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_title" && prev[1] === "ASC"
                                            ? ["position_title", "DESC"]
                                            : ["position_title", "ASC"];
                                    });
                                    setTrackSort(1);
                                }}
                            >
                                TITLE
                                <span className="ml-1">
                                    {trackSort === 1 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_location" && prev[1] === "ASC"
                                            ? ["position_location", "DESC"]
                                            : ["position_location", "ASC"];
                                    });
                                    setTrackSort(2);
                                }}
                            >
                                LOCATION
                                <span className="ml-1">
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_closing_date" && prev[1] === "ASC"
                                            ? ["position_closing_date", "DESC"]
                                            : ["position_closing_date", "ASC"];
                                    });
                                    setTrackSort(3);
                                }}
                            >
                                CLOSING DATE
                                <span className="ml-1">
                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_Created_by" && prev[1] === "ASC"
                                            ? ["position_Created_by", "DESC"]
                                            : ["position_Created_by", "ASC"];
                                    });
                                    setTrackSort(4);
                                }}
                            >
                                CREATED BY
                                <span className="ml-1">
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_interview" && prev[1] === "ASC"
                                            ? ["position_interview", "DESC"]
                                            : ["position_interview", "ASC"];
                                    });
                                    setTrackSort(5);
                                }}
                            >
                                INTERVIEWS
                                <span className="ml-1">
                                    {trackSort === 5 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th
                                style={{overflow: "hidden", cursor: "pointer"}}
                                onClick={() => {
                                    setSort((prev) => {
                                        return prev[0] === "position_evaluation" && prev[1] === "ASC"
                                            ? ["position_evaluation", "DESC"]
                                            : ["position_evaluation", "ASC"];
                                    });
                                    setTrackSort(6);
                                }}
                            >
                                EVALUATIONS
                                <span className="ml-1">
                                    {trackSort === 6 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {companyPositionsData?.Positions?.data?.map((item, index) => {
                                return (
                                    <tr key={`company-detail-position-${item.id}`}>
                                        <td>
                                            <img className="rounded-circle" width="35" src={pic1} alt=""/> {item?.title}
                                        </td>
                                        <td>{item?.location}</td>
                                        <td>{item?.closing_date ? moment(item?.closing_date).format("MMM DD, YYYY") : "N/A"}</td>
                                        <td>{item?.CreatedBy?.first_name} {item?.CreatedBy?.last_name}</td>
                                        <td>{item?.total_interview_count}</td>
                                        <td>{item?.completed_evaluated_count}</td>
                                        <td>
                                            <i
                                                className="bx bx-trash"
                                                style={{fontSize: "2rem", cursor: "pointer"}}
                                                onClick={() => {
                                                    setModalShow({
                                                        show: true,
                                                        msg: 'position',
                                                        fn: () => {
                                                            const d = {
                                                                position_id: item.id,
                                                                company_id: companyId
                                                            }
                                                            deleteCompanyPosition(d, () => {
                                                                const data = {
                                                                    "company_id": companyId,
                                                                    "pagination": {
                                                                        "page": 1,
                                                                        "size": 10
                                                                    }
                                                                }

                                                                loadCompanyPositionsData(data);
                                                                setModalShow(undefined);
                                                            })
                                                            console.log('position');
                                                        }
                                                    })
                                                }}
                                            ></i>
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-center mb-4">
                {companyPositionsData?.Positions?.totalItems > 0 &&
                    <Pagination
                        pages={companyPositionsData?.Positions?.totalPages || 1}
                        page={companyPositionsData?.Positions?.currentPage}
                        setPage={(page) => onSubmit(page)}
                        count={companyPositionsData?.Positions?.totalItems}
                    />
                }
            </div>
        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyPositionsData: (data) => dispatch(getCompanyPositionsAction(data)),
        deleteCompanyPosition: (data, cb) => dispatch(deleteCompanyPositionAction(data, cb)),
    }
}

const mapStateToProp = (state) => {
    return {
        companyPositionsData: getCompanyPositionsSelector(state)
    }
}
export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Position))
