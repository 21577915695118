import { 
  SET_AUTH_API_REQUEST, 
  SET_AUTH_API_SUCCESS, 
  SET_AUTH_API_FAILURE,
  LOGOUT_ACTION,
} from "../constants/AuthConstants"

const initalState = {
  AuthData: {
    email: "",
    token: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
    verified: undefined,
  },
  AuthDataLoading: false,
}

const AuthReducers = (state = initalState, action) => {
  switch (action.type) {
    case SET_AUTH_API_REQUEST:
      return { ...state, AuthDataLoading: true }
    case SET_AUTH_API_SUCCESS:
      return { ...state, AuthData: action.payload, AuthDataLoading: false }
    case SET_AUTH_API_FAILURE:
      return { ...state, AuthDataLoading: false }
    case LOGOUT_ACTION:
      return {
        ...state,
        AuthDataLoading:false,
        AuthData: {
          email: "",
          token: "",
          localId: "",
          expiresIn: "",
          refreshToken: "",
        },
      }
    default:
      return state
  }
}

export default AuthReducers
