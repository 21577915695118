import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoLight from "../../../images/logo-light.svg"
import LogoI from "../../../images/logo-i.svg"
import LogoDark from "../../../images/logo-dark.svg"

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img src={LogoI} alt="" className="brand-abbr" style={{height:"40px"}} />
            <img src={LogoLight} alt="" className="brand-title"  />
          </Fragment>
        ) : (
          <Fragment>
              {/* <img src={LogoI} alt="" className="brand-abbr" style={{height:"40px"}} /> */}
              <img src={LogoLight} alt="" className="brand-title"  />
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
