import React, {useState, useRef, useCallback} from "react"
import {
    Row,
    Col
} from "reactstrap";
import {getRolesSelector} from "../../../redux/selectors/AdminSelector";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';

const UserFilter = (props) => {
    const {Roles, query, setQuery} = props
    const [companyDate, setCompanyDate] = useState(null);
    const formRef = useRef(null);

    const emailChange = (value) => {
        if (value.keyCode === 13)
            setQuery({
                ...query,
                email: value.target.value,
            });
    };

    const clearFilters = () => {
        setCompanyDate(null);
        setQuery({});
        formRef.current.reset();
    };

    return (

        <div className="card candidate-filters  border border-primary">
            <div className="card-header">
                <h5 className="card-title">Filters</h5>
            </div>
            <form ref={formRef} className=" card-body pb-0 " onSubmit={(e) => {
                e.preventDefault()
            }}>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={3}>
                                <div className="form-group">
                                    <input
                                        name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        onKeyUp={(e) => emailChange(e)}
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <select
                                        name="roles"
                                        className="form-control"
                                        type="select"
                                        onChange={(e) => setQuery({...query, role: e.target.value})}
                                    >
                                        <option value="">Roles</option>
                                        {Roles.Roles && Roles?.Roles?.map((role) => <option
                                            key={`user-filter-role-${role.id}`} value={role.id}>{role.name}</option>)}
                                    </select>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <select
                                        name="Active"
                                        className="form-control"
                                        type="select"
                                        onChange={(e) => setQuery({...query, active: e.target.value})}
                                        value={query.active}
                                    >
                                        <option>Active</option>
                                        <option value={1}>True</option>
                                        <option value={0}>False</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <select
                                        name="result"
                                        className="form-control"
                                        onChange={(e) => setQuery({...query, verified: e.target.value})}
                                        value={query.verified}
                                    >
                                        <option value="">Verified</option>
                                        <option value={1}>True</option>
                                        <option value={0}>False</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2}>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <div className="form-group">
                                    <button type="button" className="btn btn-secondary btn-block"
                                            onClick={() => clearFilters()}>
                                        Clear Filters
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        Roles: getRolesSelector(state),
    }
}

export default (connect(mapStateToProps, null)(UserFilter));
