import React, {useState, useRef, useCallback} from "react"
import {
    Row,
    Col
} from "reactstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';

const LogsFilter = (props) => {
    const {query, setQuery} = props
    const [createdDate, setCreatedDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const formRef = useRef(null);

    const userNameChange = (value) => {
        // if (value.keyCode === 13)
            setQuery({
                ...query,
                user_name: value.target.value,
            });
    };

    const modalChange = (value) => {
        // if (value.keyCode === 13)
            setQuery({
                ...query,
                model: value.target.value,
            });
    };

    const urlChange = (value) => {
        // if (value.keyCode === 13)
            setQuery({
                ...query,
                url: value.target.value,
            });
    };

    const remove = useCallback((key) => {
        setQuery(({[key]: value, ...query}) => query);
    }, []);

    const handleCreatedDate = (date) => {
        if (date) {
            setCreatedDate(date);
            setQuery({
                ...query,
                created_at: moment(date).format("MMM DD, YYYY"),
            });
        } else {
            remove('created_at')
            setCreatedDate(date);
        }
    };

    const clearFilters = () => {
        setCreatedDate(null);
        setQuery({});
        formRef.current.reset();
    };

    return (

        <div className="card candidate-filters  border border-primary">
            <div className="card-header">
                <h5 className="card-title">Logs Filters</h5>
            </div>
            <form ref={formRef} className=" card-body pb-0 " onSubmit={(e) => {
                e.preventDefault()
            }}>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={3}>
                                <div className="form-group">
                                    <input
                                        name="user-name"
                                        placeholder="User name"
                                        className="form-control"
                                        onKeyUp={(e) => userNameChange(e)}
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <input
                                        name="modal"
                                        placeholder="Modal 'User'"
                                        className="form-control"
                                        onKeyUp={(e) => modalChange(e)}
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <input
                                        name="url"
                                        placeholder="Url  '/position'"
                                        className="form-control"
                                        onKeyUp={(e) => urlChange(e)}
                                        type="text"
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="form-group">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            clearable
                                            format="yyyy-MM-dd"
                                            className="form-control"
                                            placeholder="Created Date"
                                            value={createdDate}
                                            onChange={(e) => handleCreatedDate(e)
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2}>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <div className="form-group">
                                    <button type="button" className="btn btn-secondary btn-block"
                                            onClick={() => clearFilters()}>
                                        Clear Filters
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    )
}

export default (connect(null, null)(LogsFilter));
