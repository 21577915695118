import { 
  SET_AUTH_API_REQUEST, 
  SET_AUTH_API_SUCCESS, 
  SET_AUTH_API_FAILURE, 
  LOGOUT_ACTION 
} from "../constants/AuthConstants"
import { 
  loginService, 
  saveTokenInLocalStorage ,
  runLogoutTimer
} from "../../services/AuthService"
import { formatError } from "../../services/FormatError"
import { toast } from "react-toastify"

const setAuthData = (dataForm, history, cb) => {
  return (dispatch) => {
    dispatch(loginRequestAction())
    loginService(dataForm)
    .then((response) => {
      if (response.data.token) {
        console.log(response.data.token);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history)
        saveTokenInLocalStorage(response.data);
        dispatch(loginSuccessAction(response.data));
        // if (response?.data?.token && response?.data?.verified == false) {
        //   history.push("/verify")
        // }
        if (response?.data?.token && response?.data?.verified == true) {
          history.push("/analytics")
        }
      }
    })
    .catch((error) => {
      let errorMessage
      if (error.response) {
        errorMessage = formatError(error.response.data);
        if (cb) cb({
          error: error.response.status === 500 ? "Error, Please try again later!" : errorMessage,
        })
      } else {
        errorMessage = error.message;
        if (cb) cb({
          error: errorMessage,
        })
        toast.error(errorMessage, { autoClose: 2000 });
      }
      dispatch(loginFailedAction(errorMessage));
    })
  }
}

function logOutAuth (history) {
  ;(() => {
    var cookies = document.cookie.split("; ")
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".")
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path="
        var p = window.location.pathname.split("/")
        document.cookie = cookieBase + "/"
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/")
          p.pop()
        }
        d.shift()
      }
    }
  })()
  localStorage.removeItem("adminData")
  history.push("/login")
  return {
    type: LOGOUT_ACTION
  }
}

export const loginRequestAction = () => {
  return {
    type: SET_AUTH_API_REQUEST,
  }
}

export const loginSuccessAction = (data) => {
  return {
    type: SET_AUTH_API_SUCCESS,
    payload: data,
  }
}

export const loginFailedAction = (data) => {
  return {
    type: SET_AUTH_API_FAILURE,
    payload: data,
  }
}

export { setAuthData, logOutAuth }
